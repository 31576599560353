const accountSections = [
  {
    order: 3,
    label: "My Orders",
    path: "orders",
    pagePath: "Orders/Orders",
  },
  {
    order: 4,
    label: "Address Book",
    path: "addressbook",
    pagePath: "AddressBook/AddressBook",
  },
  // {
  //   order: 4,
  //   label: "Wishlist",
  //   path: "wishlist",
  //   component: React.lazy(() => import("../Pages/0/Wishlist/Wishlist"))
  // },
  {
    order: 1,
    label: "My Profile",
    path: "",
    pagePath: "Profile/Profile",
  },
];

export default accountSections
