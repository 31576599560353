import { dom, library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { faQuestionCircle as faQuestionCircleRegular } from '@fortawesome/free-regular-svg-icons'
import {
  faTv,
  faFileAlt,
  faUnlockAlt,
  faEnvelope,
  faCog,
  faDesktop,
  faMobileAlt,
  faSortDown,
  faArrowLeft,
  faSearch,
  faShoppingCart,
  faMapMarkerAlt,
  faUser,
  faUserClock,
  faArrowRight,
  faLongArrowAltLeft,
  faPhoneVolume,
  faTruck,
  faLongArrowAltRight,
  faTrashAlt,
  faLocationArrow,
  faArrowDown,
  faHashtag,
  faHome,
  faEllipsisH,
  faPhone,
  faHistory,
  faList,
  faShareAlt,
  faArrowsAlt,
  faMinus,
  faPlus,
  faTimes,
  faGlobe,
  faBell,
  faComment,
  faDotCircle,
  faCircle,
  faPencilAlt,
  faShieldAlt,
  faShoppingBag,
  faCameraRetro,
  faEdit,
  faCreditCard,
  faUserCircle,
  faLightbulb,
  faGlobeAmericas,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faFolderPlus,
  faCheck,
  faCheckCircle,
  faFile,
  faChevronDown,
  faCopyright,
  faBars,
  faFilePdf,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faSmile,
  faExternalLinkSquareAlt,
  faPrint,
  faWindowClose,
  faShippingFast,
  faDollarSign,
  faArrowCircleLeft,
  faArrowCircleRight,
  faGift,
  faStar,
  faRulerCombined,
  faHeart,
  faFilter
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faHashtag,
  faAngleDown,
  faAngleRight,
  faAngleLeft,
  faGlobeAmericas,
  faTv,
  faLightbulb,
  faShoppingBag,
  faFileAlt,
  faUnlockAlt,
  faEnvelope,
  faCog,
  faCheck,
  faCheckCircle,
  faDesktop,
  faMobileAlt,
  faSortDown,
  fab,
  faSearch,
  faTruck,
  faShoppingCart,
  faPhoneVolume,
  faUserClock,
  faMapMarkerAlt,
  faUser,
  faArrowRight,
  faArrowLeft,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faTrashAlt,
  faLocationArrow,
  faArrowDown,
  faHome,
  faEllipsisH,
  faPhone,
  faHistory,
  faList,
  faShareAlt,
  faArrowsAlt,
  faMinus,
  faPlus,
  faTimes,
  faGlobe,
  faBell,
  faComment,
  faDotCircle,
  faCircle,
  faTrashAlt,
  faPencilAlt,
  faShieldAlt,
  faCameraRetro,
  faEdit,
  faCreditCard,
  faUserCircle,
  faFolderPlus,
  faFile,
  faChevronDown,
  faCopyright,
  faBars,
  faFilePdf,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faSmile,
  faExternalLinkSquareAlt,
  faQuestionCircleRegular,
  faPrint,
  faWindowClose,
  faShippingFast,
  faDollarSign,
  faArrowCircleLeft,
  faArrowCircleRight,
  faGift,
  faStar,
  faRulerCombined,
  faHeart,
  faFilter
);

dom.watch();