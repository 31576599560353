import React, { Component } from "react";
import "./PasswordRecovery.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import Users from "../../Services/Users";
import Data from "../../Services/Data";

class PasswordRecovery extends Component {
  state = {
    password: ""
  };

  componentDidMount() {
    this.setState({
      token: new URLSearchParams(window.location.search).get("token")
    });
  }

  // get data from form with name
  getData = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  recover = event => {
    //prevent reload
    event.preventDefault();
    const {password, token, passwordConfirm} = this.state;
    if (passwordConfirm !== password) {
      toast.warning("Password doesn't match confirmed password");
      return;
    }
    Users.recover(password, token)
      .then(
        ({ org_url }) => {
            toast.success("Your password has been set successfully");
            if (org_url) window.location.assign(org_url + "/login");
        }).catch(e => {
          const { error, message } = e.response?.data || {};
          toast.error(`${Users.getErrorMessage(error, message)}`);
        });
  };

  render() {
    window.scrollTo(0, 0);
    return (
      <section className="login">
        <div className="container">
          <div className="login-cont">
            <h3>Recover Password</h3>
            <form className="form" onSubmit={this.recover}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-row">
                    <div className="col-md-12 input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon="unlock-alt" />
                        </div>
                      </div>
                      <input
                        name="password"
                        onChange={this.getData}
                        type="password"
                        className="form-control"
                        placeholder="Your Password"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-12 input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <FontAwesomeIcon icon="unlock-alt" />
                        </div>
                      </div>
                      <input
                        name="passwordConfirm"
                        onChange={this.getData}
                        type="password"
                        className="form-control"
                        placeholder="Confirm Password"
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="form-row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6"> */}
                  <button className="btn btn-submit-recover bg">
                    Recover Password
                  </button>
                  {/* </div>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default PasswordRecovery;
