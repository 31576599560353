import memoizeOne from "memoize-one";
import Data from "../Services/Data";

/**
 * gets price of stock after discount
 * @param {object} stock
 */
const getSalePrice = stock => {
  if(!stock) return Infinity;
  const { price, discount } = stock;
  return Number(price - price * ((discount || 0) / 100));
};

const formatPrice = (price) =>
  Number.isFinite(price) ? price.toLocaleString("en-us") + " " + Data.getCurrency() : "";

const getFormattedSalePrice = (stock) => {
  const salePrice = getSalePrice(stock);
  return formatPrice(salePrice);
}

/**
 * gets price of stock after discount
 * @param {object} stock
 */
const getPrices = stock => {
  if(!stock) return Infinity;
  const { price, discount } = stock;
  return {
    sale: Number(price - price * ((discount || 0) / 100)),
    original: price,
  };
};

/**
 * @param {*} variant
 */
const calculatePrice = (product, variant, shop) => {
  //trivial case
  if (variant && shop){
    // console.log("@calculatePrice variant and shop trivial case");
    return getSalePrice(
      variant.stocks.find(stock => stock.shop_id === shop.id)
    );}

  if (variant) {
    // console.log("@calculatePrice variant only",variant);
    return calculateSubPrice(variant);}

  if (product && shop){
    // console.log("@calculatePrice product and shop hard case");
    return product.variants.map(v =>
      getSalePrice(
        v.stocks
          .filter(s => s.quantity > 0 && s.price >= 0)
          .find(stock => stock.shop_id === shop.id) 
      )
    ).reduce((min,cur) => cur < min? cur:min, product.price);}

  if (product) {
    // console.log("@calculatePrice product only");
    return calculateMinPrice(product);}
    
  // console.log("@calculatePrice reaching here means we failed to get any info about the product price!!");
  throw new Error("Couldn't calculate product price!!");
};

/**
 * @param {*} variant
 */
const calculatePrices = (product, variant, shop) => {
  //trivial case
  if (variant && shop){
    // console.log("@calculatePrice variant and shop trivial case");
    return getPrices(
      variant.stocks.find(stock => stock.shop_id === shop.id)
    );
  }

  if (variant) {
    // console.log("@calculatePrice variant only",variant);
    return calculateSubPrices(variant);
  }

  if (product && shop) {
    // console.log("@calculatePrice product and shop hard case");
    return product.variants
      .map((v) =>
        getPrices(
          v.stocks
            .filter((s) => s.quantity > 0 && s.price >= 0)
            .find((stock) => stock.shop_id === shop.id)
        )
      )
      .reduce(
        (min, cur) => (cur.sale < min.sale ? cur : min),
        getPrices(product)
      );
  }

  if (product) {
    // console.log("@calculatePrice product only");
    return calculateMinPrices(product);
  }
    
  // console.log("@calculatePrice reaching here means we failed to get any info about the product price!!");
  throw new Error("Couldn't calculate product price!!");
};

/**
 * @param {*} variant
 */
const calculateSubPrice = variant => {
  var price = variant.stocks
      .filter(stock => stock.quantity && stock.quantity > 0)
      .map(stock => getSalePrice(stock))
      .reduce((min,cur) => cur < min? cur:min, Infinity);

  return Number(price);
};

/**
 * @param {*} variant
 */
const calculateSubPrices = (variant, shop) => {
  var price = variant.stocks
    .filter((stock) => stock.quantity && stock.quantity > 0 && (!shop || shop.id === stock.shop_id))
    .map((stock) => getPrices(stock))
    .reduce((min, cur) => (cur.sale < min.sale ? cur : min), {
      sale: Infinity,
      original: Infinity,
    });

  return price;
};

const getCheapestVariant = (product, shop) => {
  const variantPrices = product.variants.reduce((min, variant) => {
    const prices = calculateSubPrices(variant, shop);
    return prices.sale < min.prices.sale ? { price: prices.sale, prices, variant } : min;
  } , {
    price: Infinity,
    prices: {
      sale: Infinity,
      original: Infinity
    },
    variant: null
  });
  if (!variantPrices.variant) { // any data will do as it's out of stock
    return {
      price: getSalePrice(product),
      prices: getPrices(product),
      variant: product.variants[0]
    }
  }

  return variantPrices;
}

/**
 * gets the stock with lowest price having provided quantity
 * @param {object} variant
 * @param {number} [quantity=1]
 */
const getStockWithLowerPrice = (variant, quantity = 1) => {
  return variant.stocks && variant.stocks
    .filter(stock => stock.quantity >= quantity)
    .reduce((a, b) => (getSalePrice(a) < getSalePrice(b) ? a : b), undefined);
};

const calculateMinPrice = product => {
  console.log(product.variants);
  return product.variants
    .map(variant => getSalePrice(getStockWithLowerPrice(variant)))
    .reduce((a, b) => Math.min(a, b), product.price);
};

const calculateMinPrices = product => {
  // console.log(product.variants);
  return product.variants
    .map(variant => getPrices(getStockWithLowerPrice(variant)))
    .reduce((a, b) => a.sale < b.sale ?  a: b, getPrices(product));
};

const getSortedUrls = images => (
  images.sort((a, b) => a.priority - b.priority)
  .map(image => image.url)
);

/**
 * gets the combined gallery of variant and product
 * @param {object} product
 * @param {object} [variant]
 */
const getGallery = (product, variant = { images: [] }) => {
  const productImages = (product && product.images) || [];
  const productGallery = getSortedUrls(productImages);
  const variantImages = (variant && variant.images) || [];
  const variantGallery = getSortedUrls(variantImages);
  let gallery = [...variantGallery, ...productGallery];//.filter(({ url }) => url !== coverUrl)
  const cover = productGallery[0] || gallery[0];
  gallery = cover ? [cover, ...gallery] : gallery;
  
  if (gallery.length) {
    return Array.from(new Set(gallery)); // remove duplicates
  } else {
    return [cover]; // may be PRODUCT_DEFAULT
  }
};

/**
 * constructs a tree with features values as nodes according to featuresLabels order
 * @param {[*]} variants variants array
 * @param  {[string|object]} features an array of features or their labels
 */
const getVariantsTree = (variants, features, shop) => {
  const tree = { children: {} };
  const attributeIdFeatureLabel = {};
  features.forEach(feature => {
    if (typeof feature === "object") {
      attributeIdFeatureLabel[feature?.extra_data?.extra_attribute_id] = feature?.label;
    }
  });
  variants.filter(variant => Number.isFinite(calculateSubPrice(variant))).forEach(variant => {
    let currentNode = tree;
    const featureExtraAttribute = {};
    variant.extra_attributes.forEach((attribute) => {
      if (attribute.invisible === true) {
        const featureLabel = attributeIdFeatureLabel[attribute.id]
        if (featureLabel) {
          featureExtraAttribute[featureLabel] = attribute;
        }
      }
    });
    features.forEach(feature => {
      let label;
      if (typeof feature === "object") {
        label = feature?.label;
      } else {
        label = feature
      }
      const labelValue = variant[label];
      let currentChild = currentNode.children[labelValue];
      if (!currentChild){
        currentChild = currentNode.children[labelValue] = {children: {} };
      }
      const minPrice = calculatePrice(null, variant, shop);
      if (isFinite(minPrice) && (!currentChild.defaultVariant || minPrice < currentChild.minPrice)){
        currentChild.defaultVariant = variant;
        currentChild.extraValue = featureExtraAttribute[label]?.value/* || currentChild.extraValue */;
        currentChild.minPrice = minPrice
      }/* else if (!currentChild.extraValue && featureExtraAttribute[label]?.value) {
        currentChild.extraValue = featureExtraAttribute[label]?.value;
      } */
      currentNode = currentChild;
    });
  });
  return tree;
};

const getFeatures = (
  variantsSubtree = { children: {} },
  orderedFeatures = [],
  currentVariant
) => {
  let currentSubtree = variantsSubtree;
  const features = [];
  orderedFeatures.forEach((feature) => {
    features.push({
      title: feature.name,
      type: feature.type,
      selectedValue: currentVariant[feature.label],
      choices: Object.entries(currentSubtree.children).map(
        ([value, { defaultVariant, minPrice, extraValue }]) => ({
          label: value,
          value: value,
          extraValue,
          defaultVariantId: defaultVariant.id,
          minPrice,
        })
      ),
    });
    currentSubtree = currentSubtree.children[currentVariant[feature.label]] || {
      children: {},
    };
  });
  return features;
};

/**
 * checks if a single stock has the provided quantity
 * @param {object} variant
 * @param {number} [quantity=1]
 */
const isAvailable = (variant, quantity = 1) => {
  return variant.stocks.some(stock => stock.quantity >= quantity);
};

const calculateLongestPath = (tagsTree, nodesPaths, productTags) => {
  if(!(productTags?.length && tagsTree?.length)) return [];

  const productTagsIds = new Set(productTags.map(({ id }) => id));

  let bestNodeId = 0, bestNodeScore = 1;
  const maxDepth = Math.max(
    ...Object.values(nodesPaths).map(({ length }) => length)
  );
  const dummyRoot = { children: tagsTree, parentScore: 0 };
  const DFSStack = [{ node: dummyRoot, nextChildIndex: 0 }];
  while (DFSStack.length) {
    const depth = DFSStack.length - 1;
    const { node, nextChildIndex, parentScore } = DFSStack[DFSStack.length - 1];
    const nextChild = node.children[nextChildIndex];
    DFSStack[DFSStack.length - 1].nextChildIndex++;
    let nodeScore = 0;
    const isTagInProduct = productTagsIds.has(node.tag_id);
    if(isTagInProduct) {
      nodeScore = parentScore +
        (isTagInProduct << (maxDepth - depth)); // 0 or 1 shifted to its place value
      const bestNodeDepth = nodesPaths[bestNodeId]?.length || 0;
      if (
        bestNodeScore < nodeScore ||
        (bestNodeScore === nodeScore && depth > bestNodeDepth)
      ) {
        bestNodeId = node.node_id;
        bestNodeScore = nodeScore;
      }
    }
    
    if (nextChild) {
      DFSStack.push({
        node: nextChild,
        nextChildIndex: 0,
        parentScore: nodeScore,
      });
    } else {
      DFSStack.pop();
    }
  }

  return nodesPaths[bestNodeId] || [];
}

// will be memoized across whole session
const getShopsObject = memoizeOne((shops) =>
  shops.reduce(
    (initialValue, shop) => ({ ...initialValue, [shop.id]: shop }),
    {}
  )
);

// will be memoized accross renders for a single product
const getFiltered360Shops = memoizeOne((shop_360s, shops) => {
  const shopsObject = getShopsObject(shops);
  return shop_360s?.length
    ? shop_360s.map((shop_360) => shopsObject[shop_360])
    : [];
});

const getMinMaxPrices = (product) => {
  const { min_price, max_price } = product?.prices || {};
  if (Number.isFinite(min_price + max_price) && min_price < max_price)
    return { min: min_price, max: max_price };
  const { sale, original } = getPrices(product);
  return { min: sale, max: original };
}

const constructRatingData = (reviews) => {
  const ratingData = {
    average: 0,
    totalCount: reviews.length,
    counts: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
  }
  let ratingSum = 0;
  reviews.forEach((review) => {
    ratingData.counts[review.rate]++;
    ratingSum += review.rate;
  });
  ratingData.average =  ratingSum / ratingData.totalCount;
  return ratingData
}
const featureTypes = Object.freeze({
  COLOR: "COLOR",
  IMG_SWATCH: "IMG_SWATCH",
});

export default {
  calculatePrice,
  calculatePrices,
  getStockWithLowerPrice,
  getCheapestVariant,
  isAvailable,
  getGallery,
  getSalePrice,
  getPrices,
  calculateMinPrice,
  getVariantsTree,
  getFeatures,
  calculateSubPrice,
  calculateSubPrices,
  calculateLongestPath: memoizeOne(calculateLongestPath),
  getFiltered360Shops,
  getMinMaxPrices,
  getFormattedSalePrice,
  formatPrice,
  constructRatingData,
  featureTypes,
};
