import React from "react";
import { Redirect, Route } from "react-router-dom";
import { toast } from "react-toastify";

import Data from "./Data";

const ProtectRoute = ({
  exact,
  path,
  component: Component,
  redirect,
  loginPage,
  render,
}) => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        const { params } = props.match;
        let redirectUrl;
        if (Boolean(loginPage) === Boolean(Data.getUser())) {
          if (loginPage) {
            toast.warning("You are already logged in");
            const { redirect: encodedRedirect } = Data.getSearchParamsObject(
              props.location.search
            );
            redirectUrl = encodedRedirect
              ? decodeURIComponent(encodedRedirect)
              : redirect;
          } else {
            toast.error("Please login first", 3);
            redirectUrl = redirect || Data.getLocalUrl(path);
            for (let key in params) {
              redirectUrl = redirectUrl.replace(`:${key}`, params[key]);
            }
            redirectUrl = `login?redirect=${encodeURIComponent(redirectUrl)}`;
          }
          return <Redirect to={Data.getProperUrl(redirectUrl)} />;
        }

        const ret = (render && render(props)) || <Component />;
        return ret;
      }}
    />
  );
};

export default ProtectRoute;
