export const joinWithSpace = (...classNames) => [...classNames].join(" ");

export const getCustomStylesArray = (customStyles) =>
  Array.isArray(customStyles) ? customStyles : [customStyles];

export const joinStyles = (name, styleObjsArray, classesArray = []) =>
  joinWithSpace(
    ...styleObjsArray
      .filter((styleObj) => styleObj && styleObj[name])
      .map((styleObj) => styleObj[name])
      .concat(Array.isArray(classesArray) ? classesArray : [classesArray])
  );

export const getStylesFromArray = (...stylesArray) =>
  Object.keys(
    stylesArray.reduce((startVal, currVal) => ({ ...startVal, ...currVal }), {})
  ).reduce(
    (startVal, currVal) => ({
      ...startVal,
      [currVal]: joinStyles(currVal, stylesArray),
    }),
    {}
  );

export const getCalculatedStyles = (
  defaultStyles,
  overrideStyles,
  appendStyles = {},
  ...customStyles
) => {
  const allAppendStyles = Array.isArray(customStyles)
    ? customStyles.concat(appendStyles || {})
    : [customStyles || {}, appendStyles || {}];
  return getStylesFromArray(
    overrideStyles || defaultStyles,
    ...allAppendStyles
  );
};

export const getPrioritizedCustomStyles = (
  allCustomStyles,
  allDefaultStyles,
  overrideAll = true
) => {
  if (
    (!allCustomStyles || !Object.keys(allCustomStyles).length) &&
    (!allDefaultStyles || !Object.keys(allDefaultStyles).length)
  ) {
    return {};
  }
  const overrideStyles =
    allCustomStyles?.overrideStyles || allDefaultStyles?.overrideStyles;
  const appendStyles = overrideAll
    ? allCustomStyles?.appendStyles || allDefaultStyles?.appendStyles
    : [allCustomStyles?.appendStyles, allDefaultStyles?.appendStyles].filter(
        (style) => style
      );
  const childerenAllCustomStyles = overrideAll
    ? allCustomStyles?.childerenAllCustomStyles ||
      allDefaultStyles?.childerenAllCustomStyles ||
      {}
    : Object.keys({
        ...allCustomStyles?.childerenAllCustomStyles,
        ...allDefaultStyles?.childerenAllCustomStyles,
      }).reduce(
        (startVal, currVal) => ({
          ...startVal,
          [currVal]: getPrioritizedCustomStyles(
            allCustomStyles?.childerenAllCustomStyles
              ? allCustomStyles?.childerenAllCustomStyles[currVal]
              : {},
            allDefaultStyles?.childerenAllCustomStyles
              ? allDefaultStyles?.childerenAllCustomStyles[currVal]
              : {},
            overrideAll
          ),
        }),
        {}
      );
  return { overrideStyles, appendStyles, childerenAllCustomStyles };
};
