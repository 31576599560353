import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";

// Multiple pages (lazy)
import LazyPage from "./Pages/Multi/LazyPage";

// Multiple components (lazy)
import LazyComponent from "./Components/Multi/LazyComponent";

// Routes
import MyAccount from "./Routes/MyAccount";

// Old pages
import NotFound from "./Pages/404/NotFound";
import Main from "./Pages/Home/Main";

// Services
import Protected from "./Services/ProtectedRoute";
import Users from "./Services/Users";
import Carts from "./Services/Carts";
import Wishlists from "./Services/Wishlists";
import Data from "./Services/Data";
import { addChatSupport } from "./Services/Chat";
import Analytics from "./Services/Analytics/Analytics";
import appConfig from "./appConfig.json";

// Styles
import "./utils/Fonts";
import "./OldApp.scss";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orgName: "",
            themeId: 0,
            user: null,
            categories: [],
            tagsTree: null,
            nodesPaths: {},
            shops: [],
            brands: [],
            org: [],
            load: false,
            loading: true,
            carts: Carts.getLocalCarts() || {},
            wishlists: Wishlists.assignVariantsMaps(
                Wishlists.getLocalWishlists() || {}
            ),
            dropCart: false,
            showSearch: false,
            CurrentRoute: Route,
            orgDataReady: false,
            themeSettings: {},
            cartLoading: false,
        };

        this.getAppConfig();
    }

    componentDidMount() {
        // get organization name) to get all items from it
        const subDir = window.location.pathname.split("/")[1];
        this.getOrganizationData(subDir);
        window.addEventListener("storage", this.storageChanged);
        // const script = document.createElement("script");
        // script.src = "https://cloud.umami.is/script.js";
        // script.defer = true;
        // script.setAttribute(
        //     "data-website-id",
        //     "506b2fb9-8077-47a4-a713-c00999cc1a0b"
        // );
        // document.body.appendChild(script);
    }

    setWishlistState = (wishlist, orgId) => {
        this.setState((wishlists) => ({
            wishlists: {
                ...wishlists,
                [orgId]: Wishlists.assignVariantsMap(wishlist),
            },
        }));
    };

    componentDidUpdate(prevprops, prevstate) {
        const { CurrentRoute, load, org } = this.state;
        if (load && !prevstate.load) {
            Carts.getCart(org.id)
                .then((cart) =>
                    this.setState(({ carts }) => ({
                        carts: { ...carts, [org.id]: cart },
                    }))
                )
                .catch(() => {});
            Wishlists.getWishlist(org.id)
                .then((wishlist) => this.setWishlistState(wishlist, org.id))
                .catch(() => {});
        } else if (CurrentRoute === Protected) {
            this.setState({ CurrentRoute: Route });
        }
    }

    getAppConfig = async () => {
        const config = await fetch(
            `${process.env.REACT_APP_RESTAPI}/frontend/setting?frontend_id=${appConfig.appID}`
        );
        const data = await config.json();
        appConfig.config = { ...appConfig.config, ...data };
    };

    storageChanged = (ev) => {
        if (ev.storageArea === localStorage) {
            // eslint-disable-next-line default-case
            switch (ev.key) {
                case "users":
                    const user = Users.getUser();
                    this.setState({ user });
                    break;
                case "carts":
                    const carts = Carts.getLocalCarts();
                    this.setState({ carts });
                    break;
                case "wishlists":
                    this.setState({
                        wishlists: Wishlists.assignVariantsMaps(
                            Wishlists.getLocalWishlists() || {}
                        ),
                    });
                    break;
            }
        }
    };

    renderErrorPage = (error) => {
        let message = "";
        if (error) {
            if (error?.response?.data?.error === "G$ORG$0001") {
                message = "Organization not recognized";
            } else if (
                error?.response?.data?.message &&
                error?.response?.status < 500
            ) {
                message = error?.response?.data?.message;
            } else if (error?.response) {
                message = `Faild with status ${error?.response?.status}`;
            } else {
                message = "Network error";
            }
        } else {
            message = "Organization not recognized";
        }
        ReactDOM.render(
            <Main message={message} />,
            document.getElementById("root")
        );
    };

    getOrganizationData = (orgName) => {
        if (process.env.NODE_ENV === "development") {
            if (orgName) {
                Data.setHref(orgName);
                Data.getOrgData(orgName)
                    .then((data) => {
                        this.setInitialState(data);
                    })
                    .catch((err) => {
                        this.renderErrorPage(err);
                    });
            } else {
                this.renderErrorPage();
            }
        } else {
            Data.getOrgDataByUrl(window.location)
                .then((data) => {
                    data.sub_dir ? Data.setHref(orgName) : Data.setHref("");
                    this.setInitialState(data);
                })
                .catch((err) => {
                    this.renderErrorPage(err);
                });
        }
    };

    setInitialState = (org) => {
        org.brands = null;
        this.setState({
            themeId: Number(org.theme_id), //Remember to turn this back on before pushing
            // 1, //Use this for testing any theme
            org,
            orgName: Data.getHref().replace(/%20/g, " "),
            orgDataReady: true,
        });

        const {
            id: orgId,
            settings = {},
            theme = {},
            currency,
            matomo_site_id: matomoSiteId,
            pixel_site_id: pixelSiteId,
            google_analytics_site_id: googleAnalytics,
        } = org;

        Data.setCurrency(currency);

        const themeSettings =
            theme?.settings || theme?.default_settings?.data || {};
        /**
         * get all colors
         */
        const { colors = {} } = themeSettings;
        Object.entries(colors || {}).forEach(([color, value]) => {
            document.documentElement.style.setProperty(
                "--" + color.replace("_", "-") + "-color", // eg: --primary-color
                value
            );
        });

        Users.init(orgId, () => {
            this.logout(true);
        });
        const user = Users.getUser();
        const { email, name, id } = user || {};

        addChatSupport(settings.chat_provider, settings.chat_api_key);
        this.analytics = new Analytics(
            {
                matomo: matomoSiteId,
                pixel: pixelSiteId,
                googleAnalytics: googleAnalytics,
            },
            user ? { email, name, id } : {},
            currency
        );
        this.props.history.listen((location, action) => {
            // console.log("on route change", location, action);
            this.analytics.trackNewLocation();
        });

        //get all Data from organization
        Promise.all([
            Data.getTags(orgId),
            Data.getShops(orgId),
            Data.GetTagsStructures(orgId),
            Data.getBrands(orgId),
            // Http.get(
            //   Http.url + "/products?organization_id=" + c.data.id + "&page=1"
            // )
        ]).then((res) => {
            this.setState({
                user,
                categories: res[0],
                tagsTree: res[2].tagsTree,
                nodesPaths: res[2].nodesPaths,
                shops: res[1].content ?? res[1],
                brands: res[3].content ?? res[3],
                load: true,
                loading: false,
                themeSettings,
            });
        });
    };

    login = async (credentials, remember) => {
        const orgId = this.state.org.id;
        const user = await Users.login(
            credentials,
            () => {
                this.logout(true);
            },
            orgId,
            remember
        );
        Carts.getCart(orgId).then((cart) => {
            this.setState({
                user,
                carts: { ...this.state.carts, [orgId]: cart },
            });
        });
        Wishlists.getWishlist(orgId).then((wishlist) => {
            this.setWishlistState(wishlist, orgId);
        });
        return user;
    };

    logout = async (forced = false, beforeStateChangeCallback = () => {}) => {
        const orgId = this.state.org.id;
        try {
            await Users.logout(forced);
            const carts = await Carts.resetLocalCart(orgId);
            const wishlists = await Wishlists.resetLocalWishlist(orgId);
            const { [orgId]: wishlist } = wishlists;
            if (beforeStateChangeCallback) beforeStateChangeCallback();
            this.setState({
                user: null,
                carts,
            });
            this.setWishlistState(wishlist, orgId);
            if (forced) {
                // I don't think we need to appologize
                // toast.error("sorry, you were logged out!");
                this.setState({ CurrentRoute: Protected });
            }
        } catch (ex) {
            toast.error(ex.message);
            throw ex;
        }
    };

    modifyQuantity = async (stockId, quantity) => {
        const { id } = this.state.org;
        this.setState({ cartLoading: true });
        try {
            const carts = await Carts.modifyQuantity(id, stockId, quantity);
            this.setState({ carts });
        } catch (ex) {
            toast.error(ex.message);
        } finally {
            this.setState({ cartLoading: false });
        }
    };

    deleteFromCart = async (stockId) => {
        const { id } = this.state.org;
        this.setState({ cartLoading: true });
        try {
            const carts = await Carts.deleteFromCart(id, stockId);
            this.setState({ carts });
            if (this.analytics) this.analytics.trackCart(carts[id]);
        } catch (ex) {
            toast.error(ex.message);
        } finally {
            this.setState({ cartLoading: false });
        }
    };

    addToCart = async (itemCandidate) => {
        const { id, ecommerce } = this.state.org;
        this.setState({
            cartLoading: true,
        });
        if (ecommerce !== 1) {
            toast.warning("Sorry, cart is not available now!");
            return;
        }
        Carts.addToCart(id, itemCandidate)
            .then((carts) => {
                this.setState({ carts });
                if (this.analytics) this.analytics.trackCart(carts[id]);
                toast.success(`Product added to cart successfully`);
            })
            .catch((ex) => {
                toast.error(ex.message);
            })
            .finally(() => {
                this.setState({
                    cartLoading: false,
                });
            });
    };

    optimizeCart = async (
        addressId,
        shippingServiceId,
        promocode,
        additionalData,
        payFromReferralBalance,
        referralCode
    ) => {
        const {
            org: { id: orgId },
        } = this.state;
        const optimizationCycle = Carts.cartOptimizer(
            orgId,
            addressId,
            shippingServiceId,
            promocode,
            additionalData,
            payFromReferralBalance,
            referralCode
        );
        const {
            value: { totalChanged, itemsChanged, changedItems },
        } = await optimizationCycle.next();
        if (!itemsChanged) {
            return { totalChanged, itemsChanged };
        }

        return {
            totalChanged,
            itemsChanged,
            changedItems,
            confirmOptimization: new Promise((resolve) => {
                optimizationCycle.next(true).then(({ value: carts }) => {
                    this.setState(
                        {
                            carts,
                        },
                        () => {
                            resolve();
                        }
                    );
                });
            }),
            rejectOptimization: async () => {
                await optimizationCycle.next(false);
            },
        };
    };

    checkoutCart = async (
        customerAddressId,
        shippingServiceId,
        additionalData,
        promoCode,
        notes,
        payFromReferralBalance,
        referralCode
    ) => {
        const { id } = this.state.org;
        return Carts.checkout(
            id,
            customerAddressId,
            shippingServiceId,
            additionalData,
            promoCode,
            notes,
            payFromReferralBalance,
            referralCode
        );
    };

    confirmOrder = (order) => {
        const { order_id } = order;
        const { id } = this.state.org;
        Carts.confirmOrder(id, order_id)
            .then((carts) => {
                this.setState({ carts });
            })
            .catch((ex) => toast.error(ex.message));
        if (this.analytics) this.analytics.trackOrder(order);
    };

    deleteFromWishlist = async (stockId) => {
        const { id } = this.state.org;
        try {
            const { [id]: wishlist } = await Wishlists.deleteFromWishlist(
                id,
                stockId
            );
            this.setWishlistState(wishlist, id);
            toast.success(`Product removed from wishlist successfully`);
        } catch (ex) {
            toast.error(ex.message);
        }
    };

    addToWishlist = async (itemCandidate) => {
        const { id, ecommerce } = this.state.org;
        if (ecommerce !== 1) {
            toast.warning("Sorry, wishlist is not available now!");
            return;
        }
        Wishlists.addToWishlist(id, itemCandidate)
            .then(({ [id]: wishlist }) => {
                this.setWishlistState(wishlist, id);
                toast.success(`Product added to wishlist successfully`);
            })
            .catch((ex) => {
                toast.error(ex.message);
            });
    };

    moveWishlistItemToCart = async (item, quantity = 1) => {
        const { id, ecommerce } = this.state.org;
        if (ecommerce !== 1) {
            toast.warning("Sorry, wishlist is not available now!");
            return;
        }
        try {
            const { cart, wishlist } = await Carts.moveWishlistItemToCart(
                id,
                item,
                quantity
            );
            this.setWishlistState(wishlist, id);
            this.setState(({ carts }) => ({ carts: { ...carts, [id]: cart } }));
            toast.success(`Product moved to cart successfully`);
        } catch (ex) {
            toast.error(ex.message);
        }
    };

    hideSearch = () => {
        this.setState({ showSearch: false });
    };

    showSearch = () => {
        this.setState({ showSearch: true });
    };

    updateCart = (newData) => {
        const orgId = this.state?.org?.id;
        this.setState(({ carts }) => ({
            carts: { ...carts, [orgId]: { ...carts?.[orgId], ...newData } },
        }));
    };

    render() {
        const {
            org,
            themeId,
            orgName,
            carts,
            user,
            CurrentRoute,
            tagsTree,
            nodesPaths,
            shops,
            brands,
            categories,
            orgDataReady,
            wishlists,
            themeSettings,
        } = this.state;
        const { id: orgId, ecommerce } = org;
        const cart = carts[orgId] || { items: [] };
        const wishlist = wishlists[orgId];
        const prefix = orgName ? `/${orgName}` : "";
        const orgFavicon = orgDataReady
            ? Data.getProperImageUrl(
                  org?.themes?.favicon || org?.themes?.logo_url
              )
            : "/favicon.ico";
        return (
            // <Router>
            <React.Fragment>
                {orgDataReady && (
                    <Helmet>
                        <meta charSet='utf-8' />
                        <meta
                            name='google-site-verification'
                            content={org.google_token}
                        />
                        <link rel='icon' href={orgFavicon} />
                        <link rel='shortcut icon' href={orgFavicon} />
                        <link rel='apple-touch-icon' href={orgFavicon} />
                    </Helmet>
                )}
                <Loading
                    color={
                        themeSettings.colors?.primary ||
                        themeSettings.colors?.primary_color ||
                        "red"
                    }
                    show={this.state.loading}
                />
                {this.state.load && themeId >= 0 && (
                    <div className='App'>
                        <Route
                            exact
                            path='*'
                            render={(props) => (
                                <LazyComponent
                                    path={"Menu/Menu"}
                                    themeId={themeId}
                                    org={org}
                                    brands={brands}
                                    organizationName={org.name}
                                    user={user}
                                    logout={(beforeStateChangeCallback) =>
                                        this.logout(
                                            false,
                                            beforeStateChangeCallback
                                        )
                                    }
                                    categories={
                                        tagsTree
                                            ? tagsTree.length
                                                ? tagsTree
                                                : categories
                                            : []
                                    }
                                    cart={cart}
                                    shops={shops}
                                    deleteFromCart={this.deleteFromCart}
                                    toggleCart={this.toggleCart}
                                    dropCart={this.state.dropCart}
                                    prefix={prefix}
                                    headerSettings={themeSettings.header}
                                    modifyQuantity={this.modifyQuantity}
                                    cartLoading={this.state.cartLoading}
                                    {...props}
                                />
                            )}
                        />
                        {/* <Suspense fallback={<div />}> */}
                        <main id='mainLayout'>
                            <Switch>
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/search/results/:name/:catId/:shopId`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"Results/Results"}
                                            themeId={themeId}
                                            orgId={orgId}
                                            theme={org.themes} // required for suppling theme banners
                                            brands={brands}
                                            tagsTree={tagsTree}
                                            organizationName={org.name}
                                            categories={categories}
                                            shops={shops}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            {...props}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/returns-refunds`}
                                    redirect={"returns-refunds"}
                                    render={(props) => (
                                        <LazyPage
                                            path={
                                                "ReturnsAndRefunds/ReturnsAndRefunds"
                                            }
                                            themeId={themeId}
                                            organizationName={org.name}
                                            pageSettings={
                                                themeSettings.return_policy
                                            }
                                            {...props}
                                        />
                                    )}
                                />

                                {/* {Boolean(themeSettings.privacy_policy?.available) && ( */}
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/privacy`}
                                    render={() => (
                                        <LazyPage
                                            path={"Static/Privacy"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            pageSettings={
                                                themeSettings.privacy_policy
                                            }
                                        />
                                    )}
                                />

                                {/* {Boolean(themeSettings.terms_and_conditions?.available) && ( */}
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/terms`}
                                    render={() => (
                                        <LazyPage
                                            path={"Static/Terms"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            pageSettings={
                                                themeSettings.terms_and_conditions
                                            }
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/products`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"Products/Products"}
                                            themeId={themeId}
                                            orgId={orgId}
                                            theme={org.themes} // required for supplying theme banners
                                            brands={brands}
                                            organizationName={org.name}
                                            categories={categories}
                                            tagsTree={
                                                tagsTree
                                                    ? tagsTree.length
                                                        ? tagsTree
                                                        : categories
                                                    : []
                                            }
                                            nodesPaths={nodesPaths}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            trackPage={
                                                this.analytics
                                                    ?.trackCategoryView
                                            }
                                            cartLoading={this.state.cartLoading}
                                            org={org}
                                            {...this.props}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/search/results/:name`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"Results/Results"}
                                            themeId={themeId}
                                            orgId={orgId}
                                            theme={org.themes} // required for suppling theme banners
                                            brands={brands}
                                            organizationName={org.name}
                                            categories={categories}
                                            shops={shops}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            tagsTree={tagsTree}
                                            {...props}
                                        />
                                    )}
                                />
                                <Protected
                                    exact
                                    loginPage
                                    path={`${prefix}/register`}
                                    redirect='myaccount'
                                    render={(props) => (
                                        <LazyPage
                                            path={"SignUp/SignUp"}
                                            themeId={themeId}
                                            {...props}
                                            organizationName={org.name}
                                            orgId={orgId}
                                        />
                                    )}
                                />
                                <Protected
                                    exact
                                    loginPage
                                    path={`${prefix}/activate`}
                                    redirect='myaccount'
                                    render={(props) => (
                                        <LazyPage
                                            path={"Activation/Activation"}
                                            themeId={themeId}
                                            {...props}
                                            organizationName={org.name}
                                            orgId={orgId}
                                            user={user}
                                            login={this.login}
                                            prefix={prefix}
                                        />
                                    )}
                                />
                                <Protected
                                    exact
                                    loginPage
                                    path={`${prefix}/login`}
                                    redirect='myaccount'
                                    render={(props) => (
                                        <LazyPage
                                            path={"Login/Login"}
                                            themeId={themeId}
                                            {...props}
                                            organizationName={org.name}
                                            orgId={orgId}
                                            user={user}
                                            login={this.login}
                                        />
                                    )}
                                />
                                <Protected
                                    exact
                                    loginPage
                                    path={`${prefix}/forgot_password`}
                                    redirect='myaccount'
                                    render={(props) => (
                                        <LazyPage
                                            path={
                                                "ForgotPassword/ForgotPassword"
                                            }
                                            themeId={themeId}
                                            {...props}
                                            organizationName={org.name}
                                            orgId={orgId}
                                        />
                                    )}
                                />
                                {/* Protected routes */}
                                <Protected
                                    exact
                                    redirect={"orders/:orderId"}
                                    path={`${prefix}/orders/:orderId`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"SingleOrder/SingleOrder"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            orgId={orgId}
                                            carts={this.state.carts}
                                            brands={brands}
                                            customerSupportEmail={
                                                org.extra_info?.emails
                                                    ?.customer_support
                                            }
                                            customerSupportPhone={
                                                org.extra_info?.phones
                                                    ?.customer_support
                                            }
                                            {...props}
                                        />
                                    )}
                                />

                                {/* To be removed after all menus support my account link */}
                                <Protected
                                    exact
                                    redirect={"orders"}
                                    path={`${prefix}/orders`}
                                    render={() => (
                                        <Redirect
                                            to={`${prefix}/myaccount/orders`}
                                        />
                                    )}
                                />

                                {/* To be removed after all menus support my account link */}
                                <Protected
                                    exact
                                    redirect={"profile"}
                                    path={`${prefix}/profile`}
                                    render={() => (
                                        <Redirect to={`${prefix}/myaccount`} />
                                    )}
                                />

                                <Protected
                                    redirect={"myaccount"}
                                    path={`${prefix}/myaccount/:section?`}
                                    render={(props) => (
                                        <MyAccount
                                            {...props}
                                            themeId={themeId}
                                            orgId={orgId}
                                            organizationName={org.name}
                                            prefix={`${prefix}/myaccount`}
                                        />
                                    )}
                                />

                                {ecommerce === 1 && (
                                    <Protected
                                        path={`${prefix}/checkout`}
                                        redirect={"checkout"}
                                        render={(props) => (
                                            <LazyPage
                                                path={"Checkout/Checkout"}
                                                themeId={themeId}
                                                organizationName={org.name}
                                                carts={carts}
                                                deleteFromCart={
                                                    this.deleteFromCart
                                                }
                                                modifyQuantity={
                                                    this.modifyQuantity
                                                }
                                                optimizeCart={this.optimizeCart}
                                                checkoutCart={this.checkoutCart}
                                                confirmOrder={this.confirmOrder}
                                                orgId={orgId}
                                                shops={shops}
                                                org={org}
                                                updateCart={this.updateCart}
                                                updateLocalCart={
                                                    Carts.updateLocalCart
                                                }
                                                cartLoading={
                                                    this.state.cartLoading
                                                }
                                                {...props}
                                            />
                                        )}
                                    />
                                )}
                                <Protected
                                    exact
                                    path={`${prefix}/referral/:referralCode?`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"Referral/Referral"}
                                            themeId={themeId}
                                            org={org}
                                            organizationName={org.name}
                                            user={user}
                                            {...props}
                                        />
                                    )}
                                />
                                <Protected
                                    exact
                                    path={`${prefix}/referral/details/:type`}
                                    render={(props) => (
                                        <LazyPage
                                            path={
                                                "ReferralDetails/ReferralDetails"
                                            }
                                            themeId={themeId}
                                            org={org}
                                            organizationName={org.name}
                                            user={user}
                                            {...props}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/referral-advertisement`}
                                    render={(props) => (
                                        <LazyPage
                                            path={
                                                "ReferralAdvertisement/ReferralAdvertisement"
                                            }
                                            themeId={themeId}
                                            org={org}
                                            organizationName={org.name}
                                            user={user}
                                            {...props}
                                        />
                                    )}
                                />
                                {Boolean(
                                    themeSettings.track_order?.available
                                ) && (
                                    <CurrentRoute
                                        exact
                                        path={`${prefix}/trackorder`}
                                        redirect={"trackorder"}
                                        render={(props) => (
                                            <LazyPage
                                                path={"TrackOrder/TrackOrder"}
                                                themeId={themeId}
                                                organizationName={org.name}
                                                pageSettings={
                                                    themeSettings.track_order
                                                }
                                                returnPolicySettings={
                                                    themeSettings.return_policy
                                                }
                                                {...props}
                                            />
                                        )}
                                    />
                                )}

                                {/* {Boolean(themeSettings.return_policy?.available) && ( */}
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/returns-refunds`}
                                    redirect={"returns-refunds"}
                                    render={(props) => (
                                        <LazyPage
                                            path={
                                                "ReturnsAndRefunds/ReturnsAndRefunds"
                                            }
                                            themeId={themeId}
                                            organizationName={org.name}
                                            pageSettings={
                                                themeSettings.return_policy
                                            }
                                            {...props}
                                        />
                                    )}
                                />
                                {/* )} */}

                                {/* {Boolean(themeSettings.privacy_policy?.available) && ( */}
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/privacy`}
                                    render={() => (
                                        <LazyPage
                                            path={"Static/Privacy"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            pageSettings={
                                                themeSettings.privacy_policy
                                            }
                                        />
                                    )}
                                />
                                {/* )} */}

                                {/* {Boolean(themeSettings.terms_and_conditions?.available) && ( */}
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/terms`}
                                    render={() => (
                                        <LazyPage
                                            path={"Static/Terms"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            pageSettings={
                                                themeSettings.terms_and_conditions
                                            }
                                        />
                                    )}
                                />
                                {/* )} */}
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/about-us`}
                                    render={() => (
                                        <LazyPage
                                            path={"About/About"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            prefix={prefix}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            pageSettings={
                                                themeSettings.terms_and_conditions
                                            }
                                            shops={shops}
                                            categories={categories}
                                            tagsTree={
                                                tagsTree
                                                    ? tagsTree.length
                                                        ? tagsTree
                                                        : categories
                                                    : []
                                            }
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/contact-us`}
                                    render={() => (
                                        <LazyPage
                                            path={"Contact/Contact"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            pageSettings={
                                                themeSettings.terms_and_conditions
                                            }
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/recipes`}
                                    render={() => (
                                        <LazyPage
                                            path={"Recipes/Recipes"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/cart`}
                                    render={() => (
                                        <LazyPage
                                            path={"Cart/Cart"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            carts={carts}
                                            deleteFromCart={this.deleteFromCart}
                                            modifyQuantity={this.modifyQuantity}
                                            orgId={orgId}
                                            brands={brands}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/wishlist`}
                                    render={() => (
                                        <LazyPage
                                            path={"Wishlist/Wishlist"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            wishlists={wishlists}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            moveWishlistItemToCart={
                                                this.moveWishlistItemToCart
                                            }
                                            wishlist={wishlist}
                                            orgId={orgId}
                                            brands={brands}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/categories/:catName/:catId`}
                                    render={(props) => (
                                        <LazyPage
                                            path={
                                                "SingleCategory/SingleCategory"
                                            }
                                            themeId={themeId}
                                            orgId={orgId}
                                            theme={org.themes} // required for suppling theme banners
                                            brands={brands}
                                            organizationName={org.name}
                                            categories={categories}
                                            tagsTree={
                                                tagsTree
                                                    ? tagsTree.length
                                                        ? tagsTree
                                                        : categories
                                                    : []
                                            }
                                            nodesPaths={nodesPaths}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            trackPage={
                                                this.analytics
                                                    ?.trackCategoryView
                                            }
                                            {...props}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/categories`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"Categories/Categories"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            shops={shops}
                                            categories={categories}
                                            tagsTree={
                                                tagsTree
                                                    ? tagsTree.length
                                                        ? tagsTree
                                                        : categories
                                                    : []
                                            }
                                            nodesPaths={nodesPaths}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            {...props}
                                        />
                                    )}
                                />
                                {themeId === 9 && (
                                    <CurrentRoute
                                        exact
                                        path={`${prefix}/offers`}
                                        render={(props) => (
                                            <LazyPage
                                                path={"Offers/Offers"}
                                                themeId={themeId}
                                                organizationName={org.name}
                                                org={org}
                                                tagsTree={
                                                    tagsTree
                                                        ? tagsTree.length
                                                            ? tagsTree
                                                            : categories
                                                        : []
                                                }
                                                {...props}
                                            />
                                        )}
                                    />
                                )}
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/brands/:brandName/:brandId`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"SingleBrand/SingleBrand"}
                                            themeId={themeId}
                                            org={org}
                                            brands={brands}
                                            tagsTree={tagsTree}
                                            organizationName={org.name}
                                            shops={shops}
                                            categories={categories}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            {...props}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/brands`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"Brands/Brands"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            brands={brands}
                                            orgId={org.id}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/stores`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"Stores/Stores"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            shops={shops}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/stores/:areaName/:shopName/:shopId/products/:name/:productId`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"SingleProduct/SingleProduct"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            shops={shops}
                                            categories={categories}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            tagsTree={tagsTree}
                                            nodesPaths={nodesPaths}
                                            trackPage={
                                                this.analytics?.trackProductView
                                            }
                                            {...props}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/products/:name/:productId`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"SingleProduct/SingleProduct"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            shops={shops}
                                            categories={categories}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            tagsTree={tagsTree}
                                            nodesPaths={nodesPaths}
                                            trackPage={
                                                this.analytics?.trackProductView
                                            }
                                            user={user}
                                            {...props}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/collections/:name/:collectionId`}
                                    render={(props) => (
                                        <LazyPage
                                            path={
                                                "SingleCollection/SingleCollection"
                                            }
                                            themeId={themeId}
                                            org={org}
                                            brands={brands}
                                            shops={shops}
                                            categories={categories}
                                            organizationName={org.name}
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            tagsTree={tagsTree}
                                            nodesPaths={nodesPaths}
                                            trackPage={
                                                this.analytics?.trackProductView
                                            }
                                            user={user}
                                            {...props}
                                            // {...childProps}
                                        />
                                    )}
                                />
                                <CurrentRoute
                                    exact
                                    path={`${prefix}/`}
                                    render={(props) => (
                                        <LazyPage
                                            path={"Home/Home"}
                                            themeId={themeId}
                                            organizationName={org.name}
                                            org={org}
                                            brands={brands}
                                            shops={shops}
                                            categories={categories}
                                            tagsTree={
                                                tagsTree
                                                    ? tagsTree.length
                                                        ? tagsTree
                                                        : categories
                                                    : []
                                            }
                                            addToCart={this.addToCart}
                                            addToWishlist={this.addToWishlist}
                                            deleteFromWishlist={
                                                this.deleteFromWishlist
                                            }
                                            wishlist={wishlist}
                                            prefix={prefix}
                                            {...props}
                                        />
                                    )}
                                />
                                <Route exact path='/' component={Main} />
                                <Route
                                    path='*'
                                    render={() => (
                                        <NotFound url={Data.getProperUrl()} />
                                    )}
                                />
                            </Switch>
                        </main>
                        <LazyComponent
                            path={"Footer/Footer"}
                            themeId={themeId}
                            org={org}
                            brands={brands}
                            categories={categories}
                            tagsTree={
                                tagsTree
                                    ? tagsTree.length
                                        ? tagsTree
                                        : categories
                                    : []
                            }
                            prefix={prefix}
                            footerSettings={themeSettings.footer}
                        />
                    </div>
                )}
            </React.Fragment>
            // </Router>
        );
    }
}

export default withRouter(App);
