export const SortTypes = Object.freeze({
    ID: "ID",
    NAME: "NAME",
    P_NAME: "P_NAME",
    PRICE: "PRICE",
    CREATION_DATE: "CREATION_DATE",
    UPDATE_DATE: "UPDATE_DATE",
    PRIORITY: "PRIORITY",
});

export const SortOrder = Object.freeze({
    ASC: "ASC",
    DESC: "DESC",
});

export const DefaultSortType = SortTypes.PRIORITY;
export const DefaultSortOrder = SortOrder.DESC;
