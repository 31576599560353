import Matomo from "./Matomo";
import Pixel from "./Pixel";
import GoogleAnalytics from "./GoogleAnalytics";

export default class Analytics {
  matomo = null;
  pixel = null;
  googleAnalytics = null;

  constructor(siteIdMap, customVariables = {}, currency) {
    if (siteIdMap.matomo) {
      this.matomo = new Matomo(siteIdMap.matomo, customVariables);
    }

    if (siteIdMap.pixel) {
      this.pixel = new Pixel(siteIdMap.pixel, currency, customVariables);
    }

    if (siteIdMap.googleAnalytics) {
      this.googleAnalytics = new GoogleAnalytics(siteIdMap.googleAnalytics, currency, customVariables);
    }
  }

  trackNewLocation = () => {
    this.matomo?.trackNewLocation();
    this.pixel?.trackNewLocation();
    this.googleAnalytics?.trackNewLocation();
  };

  trackProductView = (product) => {
    this.matomo?.trackProductView(product);
    this.pixel?.trackProductView(product);
    this.googleAnalytics?.trackProductView(product);
  };

  trackCategoryView = (tag) => {
    this.matomo?.trackCategoryView(tag);
    this.pixel?.trackCategoryView(tag);
    this.googleAnalytics?.trackCategoryView(tag);
  };

  trackCart = (cart) => {
    this.matomo?.trackCart(cart);
    this.pixel?.trackCart(cart);
    this.googleAnalytics?.trackCart(cart);
  };

  trackOrder = (order) => {
    this.matomo?.trackOrder(order);
    this.pixel?.trackOrder(order);
    this.googleAnalytics?.trackOrder(order);
  };
}
