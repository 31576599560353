export default class Pixel {
  siteId = null;
  currency = null;

  constructor(siteId, currency, customVariables = {}) {
    try {
      if (!siteId) return;

      this.siteId = siteId;

      this.currency = currency;

      (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      const [firstName = "", ...lastNameComponents] = customVariables.name?.split(" ") || [];
      const lastName = lastNameComponents.join(" ");
      window.fbq("init", siteId, {
        em: customVariables.email,
        fn: firstName,
        ln: lastName,
        external_id: customVariables.id,
      });
      window.fbq("track", "PageView");
    } catch (error) {
      console.log("#Pixel", error);
    }
  }

  trackNewLocation = () => {
    if (!window.fbq) return;
    
    window.fbq("track", "PageView");
  };

  trackProductView = ({ id, name, tags = [], price }) => {
    try {
      if (!window.fbq) return;
      const [category] = tags;
      window.fbq("track", "ViewContent", {
        content_ids: [id],
        content_category: category?.name,
        content_name: name,
        content_type: "product",
        currency: this.currency,
        value: price,
      });
    } catch (error) {
      console.log("#Pixel", error);
    }
  };

  trackCategoryView = ({ name }) => {
    try {
      if (!window.fbq) return;
      window.fbq("track", "ViewContent", {
        content_name: name,
        content_type: "product_group",
      });
    } catch (error) {
      console.log("#Pixel", error);
    }
  };

  trackCart = (cart) => {
    try {
      if (!window.fbq || !cart?.items) return;

      window.fbq("track", "AddToCart", {
        content_ids: cart.items.map((item) => item.product_id),
        content_type: "product",
        contents: cart.items.map((item) => ({
          id: item.product_id,
          name: item.name,
          quantity: item.quantity,
        })),
        currency: this.currency,
        value: cart.subtotal,
      });
    } catch (error) {
      console.log("#Pixel", error);
    }
  };

  trackOrder = (order) => {
    try {
      if (!window.fbq || !order) return;

      const items = order.sub_orders
        .map(({ items }) => items)
        .reduce(
          (allItems, subOrderItems) => [...allItems, ...subOrderItems],
          []
        );
      window.fbq("track", "Purchase", {
        content_ids: items.map((item) => item.product_id),
        content_type: "product",
        contents: items.map((item) => ({
          id: item.product_id,
          name: item.name,
          quantity: item.quantity,
        })),
        currency: this.currency,
        num_items: items.reduce(
          (quantity, item) => quantity + item.quantity,
          0
        ),
        value: order.total,
      });
    } catch (error) {
      console.log("#Pixel", error);
    }
  };
}
