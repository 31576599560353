import Users from "./Users";
import Http from "./Http";

let getCarts = () => {
  return JSON.parse(localStorage.getItem("carts"));
};

let saveCarts = carts => {
  localStorage.setItem("carts", JSON.stringify(carts));
};

let addSlash = url => {
  return (url.startsWith("/") ? "" : "/") + url;
};

const socialmedia = [
  {
    img: "fab fa-facebook-f",
    link: "https://www.facebook.com"
  },
  {
    img: "fab fa-instagram",
    link: "https://www.instagram.com"
  },
  {
    img: "fab fa-youtube",
    link: "https://www.youtube.com"
  }
];

const features = [
  {
    imgHeader: "SKIN CARE",
    imgText:
      "Put your best face forward with high performance skin care. Our range of skin care products cleanses,treats."
  },
  {
    imgHeader: "SKIN CARE",
    imgText:
      "Put your best face forward with high performance skin care. Our range of skin care products cleanses,treats."
  }
];

const banner = {
  homeHeader: {
    bannerHeader: "ABD EL AZIZ EL SALLAB ",
    bannerSubHeader:
      " Welcome to El Sallab Group Enjoy VR shopping within our store and then book an appointment online with our sales team to help you build your dream...",
  },
};
export class RequestGroup {
  constructor(cancellable = true) {
    if (cancellable) {
      this.setCancelToken();
    }
  }

  setCancelToken = () => {
    this.cancelTokenSource = Http.getCancelTokenSource();
    this.cancelToken = this.cancelTokenSource.token;
  };

  cancelAndReuse = message => {
    this.cancelTokenSource.cancel(message);
    this.setCancelToken();
  };

  getOrgData = async orgName => {
    const { data }  = await Http.get(Http.url + "/organizations/13/?name=" + orgName, {
      cancelToken: this.cancelToken
    });
    return data;
  };

  getCategories = async orgId => {
    var response = await Http.get(
      Http.url + `/categories?organization_id=${Number(orgId)}`,
      { cancelToken: this.cancelToken }
    );
    return response.data;
  };

  getShops = async orgId => {
    var response = await Http.get(
      Http.url + `/shops?organization_id=${Number(orgId)}`,
      { cancelToken: this.cancelToken }
    );
    return response.data.content ?? response.data;
  };

  getProducts = async (orgId, page = 1) => {
    var response = await Http.get(
      Http.url +
        `/products?organization_id=${Number(orgId)}&page=${Number(page)}`,
      { cancelToken: this.cancelToken }
    );
    return response.data;
  };

  getProduct = async prodId => {
    console.log(prodId);
    if(!prodId || !isFinite(Number(prodId)) || Number(prodId)<=0)
      return null;
    var response = await Http.get(Http.url + "/products/" + Number(prodId), {
      cancelToken: this.cancelToken
    });
    return response.data;
  };

  getCollection = async prodId => {
    console.log(prodId);
    if(!prodId || !isFinite(Number(prodId)) || Number(prodId)<=0)
      return null;
    var response = await Http.get(Http.backendUrl + "/navbox/collection?id=" + Number(prodId), {
      cancelToken: this.cancelToken
    });
    var staticCollection = response.data;
    staticCollection.stocks = staticCollection.sub_products
      ? staticCollection.sub_products.find(
          sub => sub.stocks && sub.stocks.length > 0
        ).stocks
      : [];

    // console.log(staticCollection);

    return staticCollection;
  };

  getProductsByCategory = async (orgId, catId, page = 1) => {
    var response = await Http.get(
      Http.url +
        `/products?organization_id=${Number(orgId)}&category_id=${Number(
          catId
        )}&page=${Number(page)}`,
      { cancelToken: this.cancelToken }
    );
    return response.data;
  };

  getProductsByBrand = async (orgId, brandId, page = 1) => {
    var response = await Http.get(
      Http.url +
        `/products?organization_id=${Number(orgId)}&brand_id=${Number(
          brandId
        )}&page=${Number(page)}`,
      { cancelToken: this.cancelToken }
    );
    return response.data;
  };

  searchForProduct = async (orgId, name, catId, shopId, page = 1) => {
    var response = await Http.get(
      Http.url +
        `/products?organization_id=${Number(
          orgId
        )}&search=${name}&category_id=${catId}&shop_id=${shopId}&page=${Number(
          page
        )}`,
      { cancelToken: this.cancelToken }
    );
    return response.data;
  };

  /*for backward compatibility*/
  logout = Users.logout;
}

let DefaultRequests = new RequestGroup(false);

export default {
  getCarts,
  features,
  saveCarts,
  socialmedia,
  banner,
  useNewApi: Http.useNewApi,
  getUser: Users.getUser
};
