import React, { useState, useEffect } from "react";
import Backdrop from "../Backdrop/Backdrop";
import Styles from "./LocationModal.module.scss";

const LocationModal = ({
    isOpen: propIsOpen,
    onClose,
    onSelect,
    locations,
}) => {
    const [selectedCity, setSelectedCity] = useState("");
    const [isOpen, setIsOpen] = useState(propIsOpen);

    useEffect(() => {
        setIsOpen(propIsOpen);
    }, [propIsOpen]);

    // Listen for the custom event to open the modal
    useEffect(() => {
        const handleOpenModal = () => setIsOpen(true);
        window.addEventListener("openLocationModal", handleOpenModal);
        return () =>
            window.removeEventListener("openLocationModal", handleOpenModal);
    }, []);

    const cities = locations.map((loc) => ({
        id: loc.country.toLowerCase(),
        name: loc.country,
        icon: loc.icon,
        link: loc.link,
    }));

    const handleSelect = () => {
        const selectedLocation = cities.find(
            (city) => city.id === selectedCity
        );
        onSelect({
            city: selectedCity,
            link: selectedLocation?.link,
        });
        setIsOpen(false);
    };

    if (!isOpen) return null;
    return (
        <div className={Styles.locationModal}>
            <Backdrop backdrop={true} zIndex={1000} />
            <div className={Styles.modalContent}>
                <h2>Please Select Your Location</h2>

                <div className={Styles.citiesGrid}>
                    {cities.map((city) => (
                        <button
                            key={city.id}
                            className={`${Styles.cityButton} ${
                                selectedCity === city.id ? Styles.selected : ""
                            }`}
                            onClick={() => setSelectedCity(city.id)}
                        >
                            <img
                                className={Styles.icon}
                                src={city.icon}
                                alt={`${city.name} flag`}
                            />
                            <span>{city.name}</span>
                        </button>
                    ))}
                </div>

                <button
                    className={Styles.selectButton}
                    onClick={handleSelect}
                    disabled={!selectedCity}
                >
                    Select
                </button>
            </div>
        </div>
    );
};

export default LocationModal;
