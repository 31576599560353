import React from "react";
import { Helmet } from "react-helmet";
import NotFound from "../404/NotFound";
import Data from "../../Services/Data";
import { BounceLoader } from "../../Themes/Shared/Components/Spinners";

import styles from "./styles.module.scss";

class LazyPage extends React.Component {
  state = {
    loaded: false,
    LazyComp: null,
    path: "",
    errorFound: false,
    metaContent: "",
    metaImage: "",
  };
  defaultThemeId = 0;
  metaDescription = {};

  getComponent() {
    const path = this.props.path || "404";
    const defaultThemeId = this.props.defaultThemeId || this.defaultThemeId;
    const themeId = this.props.themeId || defaultThemeId;

    this.setState({ path, metaContent: "", metaImage: "" });

    import(`../${themeId}/${path}`)
      .then((module) => {
        this.setState((state, props) => {
          if (props.path !== path) return null;
          return {
            loaded: true,
            LazyComp: module.default,
            path,
            errorFound: false,
          };
        });
      })
      .catch((reason) => {
        import(`../${defaultThemeId}/${path}`)
          .then((module) => {
            this.setState((state, props) => {
              if (props.path !== path) return null;
              return {
                loaded: true,
                LazyComp: module.default,
                path,
                errorFound: false,
              };
            });
          })
          .catch((reason) => {
            import(`../0/${path}`).then((module) => {
              this.setState((state, props) => {
                if (props.path !== path) return null;
                return {
                  loaded: true,
                  LazyComp: module.default,
                  path,
                  errorFound: false,
                };
              });
            });
          });
      });
  }

  componentDidCatch(error, info) {
    const customizedNotFoundComponent = () => (
      <NotFound
        url={Data.getProperUrl()}
      />
    );
    this.setState({
      errorFound: true,
      loaded: true,
      LazyComp: customizedNotFoundComponent,
    });
  }

  componentDidUpdate(prevprops) {
    if (
      this.props.themeId !== prevprops.themeId ||
      this.props.path !== prevprops.path
    ) {
      document.title = "";
      this.getComponent();
    }
  }

  static getDerivedStateFromProps(props, state) {
    return props.path !== state.path
      ? { loaded: false, LazyComp: null, path: "" }
      : null;
  }

  constructor(props) {
    super(props);
    const { brands = [], org, organizationName } = props;
    this.metaDescription = {
      "Home/Home": `${organizationName}: ${org?.description}`,

      "Categories/Categories": `Shop different ${organizationName} categories`,

      "SingleCategory/SingleCategory": `Shop this unique category at  ${organizationName}`,

      "Brands/Brands": `Shop different ${organizationName} brands like "${
        brands
          ? brands
              .slice(0, 3)
              .map((brand) => brand.name)
              .join(", ")
              .concat(" and more")
          : organizationName
      }"`,

      "SingleBrand/SingleBrand": `Shop this unique brand at ${organizationName}`,

      "SingleProduct/SingleProduct": `Shop this unique product at ${organizationName}`,

      "SingleCollection/SingleCollection": `Shop this unique collection at ${organizationName}`,

      "Results/Results": `Search for unique products at ${organizationName}`,
    };
    this.titles = {
      "Home/Home": `${organizationName} | Home`,

      "Categories/Categories": `${organizationName} | Categories`,

      // "SingleCategory/SingleCategory": `${organizationName} | Category Name`,

      "Brands/Brands": `${organizationName} | Brands`,

      // "SingleBrand/SingleBrand": `${organizationName} | Brand Name`,

      // "SingleProduct/SingleProduct": `${organizationName} | Product Name`,

      // "SingleCollection/SingleCollection": `${organizationName} | Collection Name`,

      // "Results/Results": `${organizationName} | Search Results`,
    };
    this.getComponent();
  }

  updateMetaContent = (newContent, type) => {
    const { path } = this.state;
    // console.log("@HelmetReady updateMetaContent", path, newContent);
    if (path) {
      switch (type) {
        case "image":
          this.setState({ metaImage: newContent });
          break;
        default:
          this.setState({
            metaContent: newContent
              ? newContent + ". " + this.metaDescription[path]
              : this.metaDescription[path],
          });
      }
    }
  };

  render() {
    const { loaded, path, LazyComp, metaContent, metaImage } = this.state;
    const title = document.title || this.titles[path] || "";

    const { logo_url, seo_default } = this.props.org?.themes || {};
    const ogImage = metaImage || Data.getProperImageUrl(seo_default || logo_url) || "";
    return (
      <React.Fragment>
        {path && (
          <Helmet>
            {title && <title>{title}</title>}
            {title && <meta property="og:title" content={title} />}
            <meta
              name="description"
              content={metaContent || this.metaDescription[path]}
            />
            <meta
              property="og:description"
              content={metaContent || this.metaDescription[path]}
            />
            {ogImage && <meta property="og:image" content={ogImage} />}
          </Helmet>
        )}
        {loaded ? (
          <LazyComp
            {...this.props}
            updateMetaContent={this.updateMetaContent}
          />
        ) : (
            <div className={styles.loader}>
              <BounceLoader />
            </div>
        )}
      </React.Fragment>
    );
  }
}

export default LazyPage;
