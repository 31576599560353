import appConfig from "../appConfig.json";

const addTidioChatScript = (apiKey) => {
  if (apiKey) {
    const scriptElem = document.createElement("script");
    scriptElem.src = `//code.tidio.co/${encodeURIComponent(apiKey)}.js`;
    scriptElem.async = true;
    document.body.appendChild(scriptElem);
  }
}

const addTawkChatScript = (apiKey) => {
  if (apiKey) {
    const scriptElem = document.createElement("script");
    scriptElem.src = `https://embed.tawk.to/${encodeURIComponent(apiKey)}/default`;
    scriptElem.async = true;
    document.body.appendChild(scriptElem);
  }
}

const addHubSpotChatScript = (apiKey) => {
  if (apiKey) {
    const scriptElem = document.createElement("script");
    scriptElem.type = "text/javascript";
    scriptElem.id = "hs-script-loader";
    scriptElem.async = true;
    scriptElem.defer = true;
    scriptElem.src = `//js.hs-scripts.com/${encodeURIComponent(apiKey)}.js`;
    document.body.appendChild(scriptElem);
  }
}

const addMeetusChatScript = (apiKey) => {
  const addNotificationsScriptProtocol = () => {
    const scriptElem = document.createElement("script");

    scriptElem.innerHTML = `
      window.addEventListener('message', (event) => {
        if (event.data.type === "chat-widget-notification") {
          const iframeElement = document.getElementById("meetusvr-chat-widget");
          window.Notification.requestPermission().then((permission) => {
            iframeElement.contentWindow.postMessage({
                type: "notification-permission-response",
                permission: permission
            }, "*"
            );
          });
        }
      });
    `;

    document.body.appendChild(scriptElem);
  }

  if (apiKey) {
    const scriptElem = document.createElement("script");
    scriptElem.src = `${appConfig?.config?.webchat_url ?? ""}/loaderScript.js?orgId=${encodeURIComponent(apiKey)}`;
    scriptElem.type = "text/javascript";
    scriptElem.crossOrigin = "anonymous";
    scriptElem.async = true;
    scriptElem.defer = true;
    scriptElem.onload = addNotificationsScriptProtocol;
    document.body.appendChild(scriptElem);
  }
}

const chatProviders = {
  "tidio": addTidioChatScript,
  "tawk": addTawkChatScript,
  "hubspot": addHubSpotChatScript,
  "meetusvr": addMeetusChatScript
}

const addChatSupport = (provider, apiKey) => {
  const scriptGenerator = chatProviders[provider];
  if(scriptGenerator){
    scriptGenerator(apiKey);
  }
}

export { addChatSupport };
