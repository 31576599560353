import Http from "./Http";
import OldData, { RequestGroup as OldRequestGroup } from "./OldData";
import IdGenerator from "../utils/IdGenerator";
import { DefaultSortType, DefaultSortOrder } from "../utils/sort";

let href = "/";
let noImageFound = "no_img_found.jpg";
let productDefaultImage = "";
let orgCurrency = "EGP";

const setHref = (orgName) => {
    href = orgName;
};
const getHref = () => {
    return href;
};

const setCurrency = (currency) => {
    orgCurrency = currency;
};

const getCurrency = () => orgCurrency;

const getGoogleMapsKey = () => Http.MAPS_API_KEY;

let addSlash = (url, changeCase = true) => {
    //all urls should be lower case and trimmed
    if (url && changeCase) url = url.toLowerCase();
    return url && (url.startsWith("/") ? "" : "/") + encodeURI(url.trim());
};

/**
 * gets a proper url
 * @param {string} url any url starting with or without a slash
 */
let getProperUrl = (url = "") => {
    return addSlash(href) + addSlash(url);
};

/**
 * returns an absolute image url from storage
 * @param {string} imgUrl an image url
 */
const getProperImageUrl = (imgUrl) =>
    Http.filesStorageUrl + addSlash(getImageOrDefault(imgUrl), false);

/**
 * returns an absolute image url from api
 * @param {string} imgUrl an image url
 * @param {object} parameters image parameters { width, height, type }
 */
const getApiImageUrl = (imgUrl, parameters = {}) => {
    const { width, height, type } = parameters;

    const queryString = constructQueryString({ width, height, type });

    return (
        Http.filesApiUrl +
        addSlash(getImageOrDefault(imgUrl), false) +
        queryString
    );
};

const getOAuthUrl = (provider, redirect, orgId) =>
    `${
        Http.backendUrl
    }/oauth2/authorize/${provider}?redirect=${encodeURIComponent(
        redirect
    )}&org_id=${orgId}`;

const removeSlash = (url) =>
    url && url.startsWith("/") ? url.substring(1) : url;

/**
 * removes organization name from url to generate login redirect
 * @param {string} url
 */
const getLocalUrl = (url) => {
    const noSlashUrl = removeSlash(url);
    const localUrl = noSlashUrl.startsWith(href)
        ? url.substring(href.length + 1)
        : url;
    return removeSlash(localUrl);
};

/**
 * converts query parameters to an object
 * @param {string} search
 */
const getSearchParamsObject = (search) =>
    search
        .slice(1)
        .split("&")
        .map((p) => p.split("="))
        .reduce(
            (obj, [key, value]) => ({
                ...obj,
                [key]: decodeURIComponent(value),
            }),
            {}
        );

const constructQueryString = (params) =>
    "?" +
    Object.entries(params)
        .filter(([, value]) =>
            Array.isArray(value) ? value.length : value || value === 0
        )
        .map(
            ([key, value]) =>
                `${encodeURIComponent(key)}=${
                    Array.isArray(value)
                        ? value
                              .map((item) => encodeURIComponent(item))
                              .join(",")
                        : encodeURIComponent(value)
                }`
        )
        .join("&");

const ItemUrlConfig = {
    categories: [
        {
            paramIndex: -1,
            get fallback() {
                return href;
            },
        },
        { paramIndex: 1 },
        { properties: ["p_name", "name"], fallback: "name" },
        { properties: ["node_id", "id"], fallback: "id" },
    ],
    _default: [
        {
            paramIndex: -1,
            get fallback() {
                return href;
            },
        },
        { paramIndex: 1 },
        { properties: ["p_name", "name"], fallback: "name" },
        { properties: ["id"], fallback: "id" },
    ],
};

/**
 * returns a proper product/category/brand url
 * @param {object} item can be a  product, category, brand
 * @param {string} container can be any of products, categories, brands
 */
let getItemUrl = (...params) => {
    const [, container] = params;
    const config = ItemUrlConfig[container] || ItemUrlConfig._default;
    return config
        .map(({ paramIndex, properties, fallback }) => {
            let part;
            if (paramIndex !== -1) {
                const param = params[paramIndex || 0];
                if (!properties) {
                    part = param;
                } else if (param) {
                    part = properties.reduce(
                        (previousValue, currentProperty) =>
                            previousValue || param[currentProperty],
                        null
                    );
                }
            }
            part = part || fallback;
            return addSlash(encodeURIComponent(part));
        })
        .join("");
};

const parseMetadata = (metadata) => {
    let meta;
    try {
        meta = JSON.parse(metadata);
    } finally {
        return meta || {};
    }
};

class ThemesImages {
    logo_url = "";
    dark_logo_url = "";
    favicon = "";
    seo_default = "";
    product_default = "";
    first_section_image_url = "";
    second_section_image_url = "";
    third_section_image_url = "";
    fourth_section_image_url = "";
    slider_header = "";
    first_slider_images_urls = [];
    slider_images_urls = this.first_slider_images_urls; // for backword compatibility
    second_slider_images_urls = [];
    third_slider_images_urls = [];
    fourth_slider_images_urls = [];
    first_mobile_slider_images_urls = [];
    second_mobile_slider_images_urls = [];
    third_mobile_slider_images_urls = [];
    fourth_mobile_slider_images_urls = [];
    banner_top = "";
    banner_lower = "";
    banner_featured = "";
}

const orgImageTypes = {
    1: "logo_url",
    11: "dark_logo_url",
    30: "favicon",
    50: "seo_default",
    91: "product_default",
    101: "banner_top",
    111: "banner_lower",
    121: "banner_featured",
    201: "first_section_image_url",
    202: "second_section_image_url",
    203: "third_section_image_url",
    204: "fourth_section_image_url",
    300: "slider_header",
    301: "first_slider_images_urls",
    302: "second_slider_images_urls",
    303: "third_slider_images_urls",
    304: "fourth_slider_images_urls",
    321: "first_mobile_slider_images_urls",
    322: "second_mobile_slider_images_urls",
    323: "third_mobile_slider_images_urls",
    324: "fourth_mobile_slider_images_urls",
};

const getThemes = ({ themes, images }) => {
    const newThemes = new ThemesImages();
    images.forEach(({ url, type }) => {
        const key = orgImageTypes[type];
        const value = newThemes[key];
        if (Array.isArray(value)) {
            value.push(url);
        } else {
            newThemes[key] = url;
        }
    });
    themes &&
        Object.keys(themes).forEach((key) => {
            if (!newThemes[key] || !newThemes[key].length) {
                newThemes[key] = themes[key];
            }
        });
    productDefaultImage = newThemes.product_default;
    return newThemes;
};

const getImageOrDefault = (image) =>
    !image || image === noImageFound ? productDefaultImage : image;

const appendNodeData = (node) => {
    if (!node.node_id) {
        node.node_id = IdGenerator.nextId;
    }
    if (!node.children) {
        node.children = [];
    }
};

class NewRequestGroup extends OldRequestGroup {
    getOrgData = async (orgName) => {
        if (!orgName) return null;

        let { data } = await Http.get(
            Http.backendUrl +
                "/navbox/organization/?p_name=" +
                encodeURIComponent(orgName),
            { cancelToken: this.cancelToken }
        );

        if (data.images) {
            data.images = data.images.filter(({ shop_id }) => !shop_id);
            data.themes = getThemes(data);
        }
        return data;
    };

    getOrgDataByUrl = async (url) => {
        if (!url) return null;

        let { data } = await Http.get(
            Http.backendUrl +
                "/navbox/organization/?url=" +
                encodeURIComponent(url),
            { cancelToken: this.cancelToken }
        );

        if (data.images) {
            data.images = data.images.filter(({ shop_id }) => !shop_id);
            data.themes = getThemes(data);
        }
        return data;
    };

    getAddons = async (productId, shopId, orgId) => {
        const { data } = await Http.get(Http.backendUrl + "/addons/product", {
            cancelToken: this.cancelToken,
            params: {
                product_id: productId,
                shop_id: shopId,
                org_id: orgId,
            },
        });
        return data;
    };

    getCategories = async (orgId, categoryName) => {
        // get tags
        var { data } = await Http.get(
            Http.backendUrl +
                `/navbox/tags?org_id=${Number(orgId)}` +
                (categoryName ? `&category_name=${categoryName}` : ""),
            { cancelToken: this.cancelToken }
        );
        data.content.forEach((tag) => {
            tag.name = tag.alias || tag.name;
            tag.meta = parseMetadata(tag.metadata);
        });
        return data.content.sort((a, b) => {
            if (a.alias < b.alias) {
                return -1;
            }
            if (a.alias > b.alias) {
                return 1;
            }
            return 0;
        });
    };

    getTags = this.getCategories;

    getTag = async (tagId) => {
        const { data } = await Http.get(Http.backendUrl + "/navbox/tag", {
            params: {
                tag_id: tagId,
            },
        });
        data.name = data.alias || data.name;
        data.meta = parseMetadata(data.metadata);
        return data;
    };

    getTagsTree = async (orgId) => {
        const { data } = await Http.get(
            Http.backendUrl + `/navbox/tagstree?org_id=${Number(orgId)}`,
            { cancelToken: this.cancelToken }
        );
        return data;
    };

    GetTagsStructures = async (orgId) => {
        const tagsTree = (await this.getTagsTree(orgId)) || [];
        const dummyRoot = { children: tagsTree };
        const DFSStack = [{ node: dummyRoot, nextChildIndex: 0 }];
        const nodesPaths = {};
        while (DFSStack.length) {
            const { node, nextChildIndex } = DFSStack[DFSStack.length - 1];
            DFSStack[DFSStack.length - 1].nextChildIndex++;
            const nextChild = node.children[nextChildIndex];

            if (!node.path) {
                node.name = node.alias || node.name;
                node.meta = parseMetadata(node.metadata);
                nodesPaths[node.node_id] = node.path = DFSStack.slice(1).map(
                    ({ node }) => node
                );
            }

            if (nextChild) {
                appendNodeData(nextChild);
                DFSStack.push({ node: nextChild, nextChildIndex: 0 });
            } else {
                DFSStack.pop();
            }
        }
        return { tagsTree, nodesPaths };
    };

    getBrands = async (org_id) => {
        var { data } = await Http.get(
            `${Http.backendUrl}/organization/brands`,
            {
                params: { org_id },
                cancelToken: this.cancelToken,
            }
        );

        return data.content ?? data;
    };

    getShops = async (orgId, start = 0, count = 100) => {
        var { data } = await Http.get(
            Http.backendUrl +
                `/navbox/shops?org_id=${Number(
                    orgId
                )}&start=${start}&count=${count}`,
            { cancelToken: this.cancelToken }
        );
        return data.content ?? data;
    };

    sortImagesBasedonPriority = (img1, img2) => {
        return img1.priority - img2.priority;
    };

    mapToOldProduct = (newProduct) => {
        let cover_image = {};
        const {
            tags: [{ id: category_id } = {}],
        } = newProduct;
        let filteredVariants = [
            {
                stocks: [
                    {
                        id: newProduct.stock_id,
                        quantity: 1,
                        price: newProduct.price,
                        discount: newProduct.discount,
                    },
                ],
            },
        ];

        // This variants filtration + image gallery creation depends on having at least one variant
        if (newProduct.variants) {
            const { variants = [], variant_features } = newProduct;
            filteredVariants = variants.filter((variant) =>
                variant_features.every(
                    (feature) =>
                        variant[feature.label] !== null &&
                        variant[feature.label] !== undefined
                )
            );

            // If no variants passed the filter,

            // We can throw an exception
            if (!filteredVariants || !filteredVariants.length)
                throw new Error("invalid variants");

            // Or we can make sure we have at least one variant!
            filteredVariants = filteredVariants.length
                ? filteredVariants
                : [newProduct.variants[0]];

            filteredVariants.forEach((v) => {
                v.gallery_urls =
                    v.images &&
                    v.images
                        .sort(this.sortImagesBasedonPriority)
                        .filter((img) => img.url)
                        .reduce((acc, img) => acc.concat(img.url), []);
            });
        }

        // cover_image.url = getImageOrDefault(newProduct.image_url);

        if (newProduct.images && newProduct.images.length) {
            newProduct.images = newProduct.images.sort(
                this.sortImagesBasedonPriority
            );

            cover_image =
                newProduct.images.find((img) => img.priority === 0) ||
                newProduct.images[0];
        }

        const cover_image_url = getImageOrDefault(
            newProduct.image_url || cover_image.url
        );

        return {
            ...newProduct,
            category_id,
            variants: filteredVariants,
            sub_products: filteredVariants,
            cover_image_url,
            image_url: cover_image_url,
        };
    };

    mapToOldProducts = (newProducts) => {
        const products = newProducts.products || [];
        return products.map((product) => {
            return this.mapToOldProduct(product);
        });
    };

    getProduct = async (prodId, orgId) => {
        if (!prodId || !isFinite(Number(prodId)) || Number(prodId) <= 0)
            throw new Error("invalid product id");

        if (!orgId || !isFinite(Number(orgId)) || Number(orgId) <= 0)
            throw new Error("invalid organization id");

        try {
            var response = await Http.get(
                Http.backendUrl +
                    `/navbox/product?product_id=${Number(
                        prodId
                    )}&org_id=${Number(orgId)}`,
                {
                    cancelToken: this.cancelToken,
                }
            );
        } catch (error) {
            throw new Error(error.response?.data?.message);
        }

        return this.mapToOldProduct(response.data);
    };

    getSearchSuggestions = async ({ keyword, orgId, start, count, type }) => {
        const { data } = await Http.get(Http.backendUrl + "/navbox/search", {
            params: { keyword, org_id: orgId, start, count, type },
            cancelToken: this.cancelToken,
        });
        return data;
    };

    getCollection = async (collectionId) => {
        const { data } = await Http.get(
            Http.backendUrl + "/navbox/collection",
            {
                params: { id: collectionId },
                cancelToken: this.cancelToken,
            }
        );
        return { ...data, product_type: 2 };
    };

    getFilters = async (filters) => {
        const { data } = await Http.get(
            Http.backendUrl + "/navbox/filters" + constructQueryString(filters)
        );
        return data;
    };

    getProducts = async (org_id, page) => {
        return await this.searchForProductsHelper({ org_id, page }, false);
    };

    getProductsByCategory = async (org_id, catId, page) => {
        return await this.searchForProductsHelper(
            {
                org_id,
                tag_ids: catId || [],
                page,
            },
            false
        );
    };

    getProductsByBrand = async (org_id, brandId, page = 1) => {
        return await this.searchForProductsHelper(
            {
                org_id,
                brand_id: Number(brandId || 0),
                page,
            },
            false
        );
    };

    searchForProduct = async (org_id, name, category_id, shop_id, page = 1) => {
        return await this.searchForProductsHelper(
            {
                org_id,
                name,
                category_id,
                shop_id,
                page,
            },
            true
        );
    };

    getCollections = async (org_id, start, count) => {
        return await this.searchForProductsHelper({
            org_id,
            product_type: 2,
            start,
            count,
        });
    };

    getPagesGenerator = async function* (getPage, filters, defaultCount, sort) {
        const { start } = filters;
        let { count: newCount } = filters;
        let total = Infinity;
        let count;
        const { sortType, sortOrder } = sort || {};
        for (
            let currentStart = start || 0;
            currentStart <= total;
            currentStart += count
        ) {
            count = newCount; // static until api is fixed
            const data = await getPage({
                ...filters,
                start: currentStart,
                count,
                sort: sortType,
                order: sortOrder,
            });
            total = data.total;
            if (currentStart + count) newCount = (yield data) || defaultCount;
            else return data;
        }
    };

    searchForProductsHelper = async (params, total = true) => {
        const {
            pageSize = 12,
            start = (params.page - 1) * pageSize,
            count = pageSize,
            sort = DefaultSortType,
            order = DefaultSortOrder,
            product_type = 0,
            ...otherParams
        } = params;
        const queryObject = {
            ...otherParams,
            start,
            count,
            sort,
            order,
            product_type,
        };
        delete queryObject.page;
        var response = await Http.get(
            Http.backendUrl +
                `/navbox/products${constructQueryString(queryObject)}`,
            { cancelToken: this.cancelToken }
        );
        const oldProducts = this.mapToOldProducts(response.data);
        response.data = { ...response.data, products: oldProducts };
        return total ? response.data : oldProducts;
    };

    getSearchShops = async ({
        orgId,
        name,
        areaId,
        minLongitude,
        maxLongitude,
        minLatitude,
        maxLatitude,
        longitude,
        latitude,
        radius,
        searchInTags = false,
        productType = 0,
    } = {}) => {
        const { data } = await Http.get(
            `${Http.backendUrl}/navbox/location_shops`,
            {
                params: {
                    org_id: orgId,
                    name,
                    area_id: areaId,
                    minLongitude,
                    maxLongitude,
                    minLatitude,
                    maxLatitude,
                    longitude,
                    latitude,
                    radius,
                    searchInTags,
                    product_type: productType,
                },
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    getCountries = async (orgId, hideEmptyCities) => {
        const { data } = await Http.get(`${Http.backendUrl}/navbox/countries`, {
            params: { org_id: orgId, hide_empty_cities: hideEmptyCities },
            cancelToken: this.cancelToken,
        });
        return data;
    };

    getOrderList = async (orgId, status, detailsLevel = 1) => {
        var response = await Http.get(
            `${Http.backendUrl}/order/list?org_id=${orgId}&details_level=${detailsLevel}` +
                (status ? `&status=${status}` : "")
        );
        return response.data;
    };

    getOrderInfo = async (orderId, detailsLevel = 3) => {
        var response = await Http.get(
            `${Http.backendUrl}/order/info?order_id=${orderId}&details_level=${detailsLevel}`
        );
        return response.data;
    };

    getMetaOrdersList = async () => {
        var { data } = await Http.get(
            `${Http.backendUrl}/order/meta_order/list/user`,
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    getMetaOrderInfo = async (metaOrderId) => {
        var { data } = await Http.get(
            `${Http.backendUrl}/order/meta_order/info`,
            {
                params: {
                    id: metaOrderId,
                },
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    getOrderCurrent = async (detailsLevel = 3) => {
        var response = await Http.get(
            `${Http.backendUrl}/order/current?details_level=${detailsLevel}`
        );
        return response.data;
    };

    deleteOrderCurrent = async () => {
        var response = await Http.delete(`${Http.backendUrl}/order/current`);
        return response.data;
    };

    postOrderCancel = async (metaOrderId) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/order/cancel?meta_order_id=${metaOrderId}`
        );
        return data;
    };

    postOrderReturn = async (items) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/order/return`,
            {
                item_list: items,
            },
            { cancelToken: this.cancelToken }
        );
        return data;
    };

    getShippingOffers = async (addressId) => {
        const { data } = await Http.get(`${Http.backendUrl}/shipping/offers`, {
            params: { customer_address: addressId },
            cancelToken: this.cancelToken,
        });
        return data;
    };

    postOrderUpdate = async (order) => {
        var response = await Http.post(
            `${Http.backendUrl}/order/update`,
            order
        );
        return response.data;
    };

    postOrderCreate = async (order) => {
        var { data } = await Http.post(
            `${Http.backendUrl}/order/create`,
            order
        );
        return data;
    };

    getCart = async (promo) => {
        var { data } = await Http.get(`${Http.backendUrl}/cart/v2`, {
            params: { promo },
            cancelToken: this.cancelToken,
        });
        data.items.sort(({ id: a_id }, { id: b_id }) => a_id - b_id);
        return data;
    };

    postCartItem = async (item) => {
        var { data } = await Http.post(`${Http.backendUrl}/cart/item`, item, {
            cancelToken: this.cancelToken,
        });
        data.items.sort(({ id: a_id }, { id: b_id }) => a_id - b_id);
        return data;
    };

    deleteCartItem = async (itemId) => {
        var { data } = await Http.delete(`${Http.backendUrl}/cart/item`, {
            params: { item_id: itemId },
            cancelToken: this.cancelToken,
        });
        data.items.sort(({ id: a_id }, { id: b_id }) => a_id - b_id);
        return data;
    };

    postCartOptimize = async (
        addressId,
        shippingServiceId,
        promocode,
        additionalData,
        payFromReferralBalance,
        referralCode
    ) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/cart/optimize`,
            {
                customer_address: addressId,
                shipping_service_id: shippingServiceId,
                promo_code: promocode,
                additional_data: additionalData,
                payFromReferralBalance,
                referralCode,
            },
            {
                cancelToken: this.cancelToken,
            }
        );
        data.cart.items.sort(({ id: a_id }, { id: b_id }) => a_id - b_id);
        return data;
    };

    postCartItems = async (items, promo) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/cart/items`,
            items,
            {
                params: { promo },
                cancelToken: this.cancelToken,
            }
        );
        data.items.sort(({ id: a_id }, { id: b_id }) => a_id - b_id);
        return data;
    };

    getWishlist = async () => {
        var { data } = await Http.get(`${Http.backendUrl}/wishlist`, {
            cancelToken: this.cancelToken,
        });
        data.items.sort(({ id: a_id }, { id: b_id }) => b_id - a_id);
        return data;
    };

    postWishlistItem = async (item) => {
        var { data } = await Http.post(
            `${Http.backendUrl}/wishlist/item`,
            item,
            {
                cancelToken: this.cancelToken,
            }
        );
        data.items.sort(({ id: a_id }, { id: b_id }) => b_id - a_id);
        return data;
    };

    moveWishlistITemToCart = async (itemId, quantity) => {
        var { data } = await Http.post(
            `${Http.backendUrl}/wishlist/item/into_cart`,
            { item_id: itemId, quantity },
            {
                cancelToken: this.cancelToken,
            }
        );
        data.items.sort(({ id: a_id }, { id: b_id }) => b_id - a_id);
        return data;
    };

    deleteWishlistItem = async (itemId) => {
        var { data } = await Http.delete(`${Http.backendUrl}/wishlist/item`, {
            params: { item_id: itemId },
            cancelToken: this.cancelToken,
        });
        data.items.sort(({ id: a_id }, { id: b_id }) => b_id - a_id);
        return data;
    };

    getCartPromoDiscount = async (promo) => {
        const { data } = await Http.get(
            `${Http.backendUrl}/cart/promo/discount`,
            {
                params: { promo },
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    postCartCheckout = async (
        customerAddressId,
        shippingServiceId,
        additionalData,
        promoCode,
        notes,
        payFromReferralBalance,
        referralCode
    ) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/cart/checkout`,
            {
                customer_address: customerAddressId,
                shipping_service_id: shippingServiceId,
                additional_data: additionalData,
                promo_code: promoCode,
                notes,
                payFromReferralBalance,
                referralCode,
            },
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    getPaymentGateway = async (org_id, delivery) => {
        var { data } = await Http.get(
            `${Http.backendUrl}/organization/payments`,
            {
                params: { org_id, delivery },
            }
        );
        return data;
    };

    paymentPaymobCardInitialize = async (orderId) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/payment/paymob/card/init?order_id=${orderId}`,
            null,
            { cancelToken: this.cancelToken }
        );
        return data;
    };

    paymentPaymobCardConfirm = async (token) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/payment/paymob/card/confirm?token=${token}`,
            null,
            { cancelToken: this.cancelToken }
        );
        return data;
    };

    paymentExecuteCodOrders = async (orderId) => {
        var response = await Http.post(
            `${Http.backendUrl}/payment/cod/execute?order_id=${orderId}`,
            null,
            { cancelToken: this.cancelToken }
        );
        return response.data;
    };

    paymentMcardInitialize = async (orderId) => {
        var response = await Http.post(
            `${Http.backendUrl}/payment/mcard/initialize?order_id=${orderId}`,
            null,
            { cancelToken: this.cancelToken }
        );
        const { execute_url } = response.data;
        const execute = async (session_id) => {
            var innerResponse = await Http.post(
                `${Http.backendUrl}${addSlash(
                    execute_url
                )}?session_id=${session_id}`,
                { cancelToken: this.cancelToken }
            );
            return innerResponse.data;
        };
        return { data: response.data, execute };
    };

    paymentMcardVerify = async (order_uid, resultIndicator) => {
        const resultIndicatorValue = await resultIndicator.resultIndicator;
        var innerResponse = await Http.get(
            `${Http.backendUrl}/payment/mcard/verify?order_uid=${order_uid}&resultIndicator=${resultIndicatorValue}`,
            { cancelToken: this.cancelToken }
        );
        return innerResponse.data;
    };

    paymentUPGInitialize = async (orderId) => {
        const { data } = await Http.get(
            `${Http.backendUrl}/payment/upg/initialize?order_id=${orderId}`,
            { cancelToken: this.cancelToken }
        );
        return data;
    };

    paymentUPGCallback = async (payload) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/payment/upg/callback`,
            payload,
            { cancelToken: this.cancelToken }
        );
        return data;
    };

    paymentMCardLog = async (level, message) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/payment/mcard/log`,
            null,
            { params: { level, message }, cancelToken: this.cancelToken }
        );
        return data;
    };

    paymentRaveInitialize = async (orderId) => {
        const { data } = await Http.get(
            `${Http.backendUrl}/payment/rave/initialize`,
            {
                params: { order_id: orderId },
                cancelToken: this.cancelToken,
            }
        );
        return { data };
    };

    paymentRaveSuccess = async (uid, ref) => {
        var innerResponse = await Http.get(
            `${Http.backendUrl}/payment/rave/success`,
            { params: { uid, ref }, cancelToken: this.cancelToken }
        );
        return innerResponse.data;
    };
    getApplicablePromotions = async ({
        brandIds = [],
        productIds = [],
        tagIds = [],
        promotionsLimit,
    }) => {
        const { data } = await Http.get(
            `${Http.backendUrl}​/navbox​/applicable_promotions_list`,
            {
                cancelToken: this.cancelToken,
                params: {
                    brand_ids: brandIds.join(","),
                    product_ids: productIds.join(","),
                    tag_ids: tagIds.join(","),
                    promotions_per_item: promotionsLimit,
                },
            }
        );
        return data;
    };
    getActivePromotionsList = async (orgId, typeIds = []) => {
        const { data } = await Http.get(
            `${Http.backendUrl}/navbox/active_promotions`,
            {
                cancelToken: this.cancelToken,
                params: {
                    org_id: orgId,
                    type_ids: typeIds.join(","),
                },
            }
        );
        return data;
    };
    postProductReview = async (suborderId, variantId, rate, review) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/product/review`,
            {
                order_id: suborderId,
                variant_id: variantId,
                rate,
                review,
            },
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };
    postCreateVideoSession = async (orgId) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/videochat/session`,
            null,
            {
                params: { org_id: orgId },
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    getLoyaltyPoints = async () => {
        const { data } = await Http.get(`${Http.backendUrl}/loyalty/points`, {
            cancelToken: this.cancelToken,
        });
        return data;
    };

    getLoyaltyPointsHistory = async () => {
        const { data } = await Http.get(
            `${Http.backendUrl}/loyalty/points/list`,
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    getUserTier = async () => {
        const { data } = await Http.get(
            `${Http.backendUrl}/loyalty/user_tier`,
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    sharePoints = async (org_id, email, points) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/loyalty/share_points`,
            null,
            {
                params: {
                    org_id,
                    email,
                    points,
                },
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    getProductsWithFilter = async (params) => {
        const { data } = await Http.get(
            Http.backendUrl + `/navbox/products${constructQueryString(params)}`,
            { cancelToken: this.cancelToken }
        );
        return data;
    };

    getActivePromotionsOnline = async (orgId) => {
        const { data } = await Http.get(
            `${Http.backendUrl}/navbox/promotion/online`,
            {
                cancelToken: this.cancelToken,
                params: {
                    org_id: orgId,
                },
            }
        );
        return data;
    };

    // Referral APIS Start

    getReferralUser = async () => {
        const { data } = await Http.get(`${Http.backendUrl}/referral/user`, {
            cancelToken: this.cancelToken,
        });
        return data;
    };

    getReferralStats = async () => {
        const { data } = await Http.get(`${Http.backendUrl}/referral/stats`, {
            cancelToken: this.cancelToken,
        });
        return data;
    };

    getReferralList = async (pageNo, pageSize) => {
        const { data } = await Http.get(`${Http.backendUrl}/referral/list`, {
            cancelToken: this.cancelToken,
            params: {
                pageNo,
                pageSize,
            },
        });
        return data;
    };

    getReferralChilds = async (pageNo, pageSize, type, dateFrom, dateTo) => {
        const { data } = await Http.get(`${Http.backendUrl}/referral/childs`, {
            cancelToken: this.cancelToken,
            params: {
                pageNo,
                pageSize,
                type,
                dateFrom,
                dateTo,
            },
        });
        return data;
    };

    getReferralCode = async (ReferralCode) => {
        const { data } = await Http.get(
            `${Http.backendUrl}/referral/code/${ReferralCode}`,
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    getReferralDiscountPercentage = async () => {
        const { data } = await Http.get(
            `${Http.backendUrl}/referral/settings/discount_percentage`,
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    postResendReferralOtp = async () => {
        const { data } = await Http.post(
            `${Http.backendUrl}/referral/resendOtp`,
            null,
            { cancelToken: this.cancelToken }
        );
        return data;
    };

    postSendReferralOtp = async (phoneNumber, parentReferralCode = null) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/referral/sendOtp`,
            null,
            {
                params: { phoneNumber, parentReferralCode },
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    postValidateReferralOtp = async (otp) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/referral/validateOtp/${otp}`,
            null,
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    postActiveReferralCode = async (referralCode) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/referral/activate/${referralCode}`,
            null,
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    postDeactivateReferralCode = async (referralCode) => {
        const { data } = await Http.post(
            `${Http.backendUrl}/referral/deactivate/${referralCode}`,
            null,
            {
                cancelToken: this.cancelToken,
            }
        );
        return data;
    };

    // Referral APIS End
}

// export const RequestGroup = Http.useNewApi ? NewRequestGroup : OldRequestGroup;
export const RequestGroup = NewRequestGroup;

let DefaultRequests = new RequestGroup(false);

export default {
    ...OldData,
    ...DefaultRequests,
    getProperImageUrl,
    getApiImageUrl,
    getOAuthUrl,
    getProperUrl,
    getItemUrl,
    setHref,
    getHref,
    removeSlash,
    getLocalUrl,
    getSearchParamsObject,
    getImageOrDefault,
    setCurrency,
    getCurrency,
    getGoogleMapsKey,
};
