import React from 'react';
import { joinStyles } from '../../helperFunctions/helperFunctions';

import classes from "./BounceLoader.module.scss";

const BounceLoader = ({ topMsg, bottomMsg, customStyles, className }) => {
  
  const stylesArray = [classes].concat(customStyles ? customStyles : []);
  const classesArray = [className];

  return (
    <div className={joinStyles('BounceLoader', stylesArray, classesArray)}>
      <div className={joinStyles('topMsg', stylesArray)}>{topMsg}</div>
      <div className={joinStyles('shapeContainer', stylesArray)}></div>
      <div className={joinStyles('bottomMsg', stylesArray)}>{bottomMsg}</div>
    </div>
  )
}
export default BounceLoader;
