export default class Matomo {
  _paq = (window._paq = window._paq || []);

  constructor(siteId, customVariables = {}) {
    try {
      if (!siteId) return;
      matomoSiteId = this.siteId = siteId;
      this.currentUrl = window.location.href;
      const DOMAIN = window.location.hostname;
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      // you can set up to 5 custom variables for each visitor
      Object.keys(customVariables).forEach((key, index) =>
        window._paq.push([
          "setCustomVariable",
          index + 1,
          key,
          customVariables[key],
          "visit",
        ])
      );

      window._paq.push(["trackPageView"]);
      window._paq.push(["enableLinkTracking"]);

      const u = `//${DOMAIN}/analytics/`;
      window._paq.push(["setTrackerUrl", u + "matomo.php"]);
      window._paq.push(["setSiteId", siteId]);
      window._paq.push([
        "setUserId",
        customVariables.email || customVariables.id,
      ]);

      const scriptElem = document.createElement("script");
      scriptElem.src = u + "matomo.js";
      scriptElem.async = true;
      document.body.appendChild(scriptElem);
    } catch (error) {
      console.log("#Matomo", error);
    }
  }

  trackNewLocation = () => {
    // console.log('#Matomo', this.siteId, window._paq);
    if (!this.siteId || !window._paq) return;
    // waiting for document title to be generated
    setTimeout(() => {
      window._paq.push(["setReferrerUrl", this.currentUrl]);
      this.currentUrl = window.location.href;
      window._paq.push(["setCustomUrl", this.currentUrl]);
      window._paq.push(["setDocumentTitle", document.title]);

      // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
      window._paq.push(["deleteCustomVariables", "page"]);
      // window._paq.push(['setGenerationTimeMs', 0]);
      window._paq.push(["trackPageView"]);

      window._paq.push(["enableLinkTracking"]);
    }, 1000);
  };

  trackProductView = ({ id, name, tags = [], price }) => {
    // console.log('#Matomo', this.siteId, window._paq);
    try {
      if (!this.siteId || !window._paq) return;
      const [category] = tags;
      // Push Product View Data to Matomo - Populate parameters dynamically
      window._paq.push([
        "setEcommerceView",
        id, // (Required) productSKU
        name, // (Optional) productName
        category?.name, // (Optional) categoryName
        price, // (Optional) price
      ]);
      window._paq.push(["setDocumentTitle", document.title]);

      // You must also call trackPageView when tracking a product view
      window._paq.push(["trackPageView"]);
    } catch (error) {
      console.log("#Matomo", error);
    }
  };

  trackCategoryView = ({ name }) => {
    // console.log('#Matomo', this.siteId, window._paq);
    try {
      if (!this.siteId || !window._paq) return;

      // Push Product View Data to Matomo - Populate parameters dynamically
      window._paq.push([
        "setEcommerceView",
        false, // (Required) productSKU
        false, // (Optional) productName
        name, // (Optional) categoryName
      ]);
      window._paq.push(["setDocumentTitle", document.title]);

      // You must also call trackPageView when tracking a product view
      window._paq.push(["trackPageView"]);
    } catch (error) {
      console.log("#Matomo", error);
    }
  };

  trackCart = (cart) => {
    // console.log('#Matomo', this.siteId, window._paq);
    try {
      if (!this.siteId || !window._paq || !cart?.items) return;

      // An addEcommerceItem push should be generated for each cart item, even the products not updated by the current "Add to cart" click.
      cart.items.forEach((item) => {
        window._paq.push([
          "addEcommerceItem",
          item.product_id, // (Required) productSKU
          item.name, // (Optional) productName
          false, // (Optional) productCategory
          item.total_price || item.price, // (Recommended) price
          item.quantity, // (Optional, defaults to 1) quantity
        ]);
      });

      // Pass the Cart's Total Value as a numeric parameter
      window._paq.push(["trackEcommerceCartUpdate", cart.subtotal]);
    } catch (error) {
      console.log("#Matomo", error);
    }
  };

  trackOrder = (order) => {
    // console.log('#Matomo', this.siteId, window._paq);
    try {
      if (!this.siteId || !window._paq || !order) return;

      // An addEcommerceItem push should be generated for each cart item, even the products not updated by the current "Add to cart" click.
      order.sub_orders.forEach((sub) =>
        sub.items.forEach((item) => {
          window._paq.push([
            "addEcommerceItem",
            item.product_id, // (Required) productSKU
            item.name, // (Optional) productName
            false, // (Optional) productCategory
            item.total_price || item.price, // (Recommended) price
            item.quantity, // (Optional, defaults to 1) quantity
          ]);
        })
      );
      // Order Array - Parameters should be generated dynamically
      window._paq.push([
        "trackEcommerceOrder",
        order.order_id, // (Required) orderId
        order.total, // (Required) revenue
        order.subtotal, // (Optional) subTotal
        0, // (optional) tax
        order.shipping, // (optional) shipping
        order.discount, // (optional) discount
      ]);
    } catch (error) {
      console.log("#Matomo", error);
    }
  };
}

export var matomoSiteId;
