import React, { Component } from "react";
import { Helmet } from "react-helmet";
import "./Main.scss";

class Main extends Component {
  // state = {};

  componentDidMount() {
    // this.setOverflow("hidden");
    document.body.style.height = "auto";
  }

  // setOverflow = (overflow) => {
  //   document.body.style.overflow = overflow;
  // };

  // componentWillUnmount() {
  //   this.setOverflow(null);
  // }
  render() {
    const { message } = this.props;
    return (
      <div className="main">
        <Helmet>
          <meta name="render:status_code" content="404" />
          <meta charSet="utf-8" />
          <meta
            name="description"
            content={`Create your store website with our unique platform, choose a stunning customized design and upload your products based on location & even push campaigns using ibeacons. Easy to setup, no coding skills needed.`}
          />
        </Helmet>
        <div className="container">
          <div className="contentWrapper">
            <figure className="serach-btn">
              <img src="/img/SearchPatternBtn.png" className="search-pattern" alt="NasNav Search Pattern" />
            </figure>
            <div className="details">
              {message && <h3>{message}</h3>}
              <p>
                Shopping in {new Date().getFullYear()} requires something smart 
                and here's something special from MeetsusVR, an app that gives you a 
                way to search for any product in any store in the comfort of your home
              </p>
              <a href="/">
                Go home
              </a>
            </div>
            <figure className="main-slide">
              <img src="/img/404Bg.png" alt="NasNav" />
            </figure>
          </div>
        </div>
      </div>
    );
  }
}

export default Main;
