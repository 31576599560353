import { ItemListHandler } from "./ItemsListsHandler";

const itemsListsHandler = new ItemListHandler("wishlist");

const assignVariantsMap = (wishlist) => {
  const variantsMap = {};
  wishlist.items.forEach(item => {
    variantsMap[item.variant_id] = item;
  });
  wishlist.variantsMap = variantsMap;
  return wishlist;
}

const assignVariantsMaps = (wishlists) => {
  Object.values(wishlists).forEach((wishlist) => {
    assignVariantsMap(wishlist)
  })
  return wishlists;
}

export default { 
  getLocalWishlists: itemsListsHandler.getLocalLists,
  getWishlist: itemsListsHandler.getList,
  saveWishlist: itemsListsHandler.saveBackendList,
  addToWishlist: itemsListsHandler.addToList,
  deleteFromWishlist: itemsListsHandler.deleteFromList,
  resetLocalWishlist: itemsListsHandler.resetLocalList,
  assignVariantsMap,
  assignVariantsMaps
};
