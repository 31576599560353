import axios from "axios";

// Add a response interceptor
axios.interceptors.response.use(
    function(response) {
      // Do something with response data
      return response;
    },
    function(error) {
      if (error.response && error.response.status === 401) unauthorized();
      return Promise.reject(error);
    }
);

let backendUrl;

if (process.env.REACT_APP_BUILD_YESHTERY === "true") {
  backendUrl = "https://api-yeshtery.dev.meetusvr.com/v1";
  // backendUrl = "https://api-uat.yeshtery.com/v1";
  // backendUrl = "https://api.yeshtery.com/v1";
} else {
  backendUrl = "https://api.dev.meetusvr.com";
  // backendUrl = "https://api-uat.nasnav.org";
  // backendUrl = "https://api.nasnav.com";
}

let MAPS_API_KEY = "";

/**
 * Debugging env variables
 */
localStorage.setItem("Env", JSON.stringify(process.env));

//Preparing the app for the new API
//set "REACT_APP_RESTAPI=url"
//export REACT_APP_RESTAPI=url
// console.log("REACT_APP_RESTAPI: " + process.env.REACT_APP_RESTAPI);

if (process.env.REACT_APP_RESTAPI) {
  backendUrl = process.env.REACT_APP_RESTAPI.includes("yeshtery") ? process.env.REACT_APP_RESTAPI + "/v1" : process.env.REACT_APP_RESTAPI;
  // axios
  //   .get(backendUrl + "/navbox/organization?p_name=nasnav") // check if the provided url works
  //   .then(() => {
  //     // backendUrl = process.env.REACT_APP_RESTAPI;
  //     // console.log("Setting backend url to: ", backendUrl);
  //   })
  //   .catch(error => {
  //     console.log("Error setting backend url to ", backendUrl);
  //     console.log("Falling back to: ", fallbackBackendUrl);
  //     console.log("Error: ", error);
  //     backendUrl = fallbackBackendUrl;
  //     filesApiUrl = `${backendUrl}/files`;
  //   });
}

const filesStorageUrl =
    process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_BUILD_YESHTERY === "true"
            ? "https://api-yeshtery.dev.meetusvr.com/files"
            : "https://api.dev.meetusvr.com/files"
        : "/files";

const filesApiUrl = process.env.REACT_APP_BUILD_YESHTERY === "true"
    ? `${backendUrl}/yeshtery/files`
    : `${backendUrl}/files`;

if (process.env.REACT_APP_GMAPS) {
  MAPS_API_KEY = process.env.REACT_APP_GMAPS;
}

const save360Cookie = (userToken) => {
  const pannellumCookie = {
    backend_api_url: backendUrl,
    files_api_url: filesStorageUrl,
    user_token: userToken,
  };
  document.cookie = `360=${JSON.stringify(pannellumCookie)}; path=/;`;
}

save360Cookie();

let unauthorized = () => {};

const startUserSession = (userId, token, unauthorizedCallback) => {
  axios.defaults.headers["User-Token"] = token;
  axios.defaults.headers["User-ID"] = userId;
  unauthorized = unauthorizedCallback;
  save360Cookie(token);
};

const endUserSession = () => {
  delete axios.defaults.headers["User-Token"];
  delete axios.defaults.headers["User-ID"];
  unauthorized = () => {};
  save360Cookie();
};

export default {
  backendUrl,
  filesStorageUrl,
  filesApiUrl,
  useNewApi: true,
  MAPS_API_KEY,
  get: axios.get,
  post: axios.post,
  delete: axios.delete,
  put: axios.put,
  patch: axios.patch,
  startUserSession,
  endUserSession,
  getCancelTokenSource: () => {
    return axios.CancelToken.source();
  }
};
