import React, { Component } from "react";

class LazyComponent extends Component {
  state = { loaded: false, LazyComp: null, path: ""  };
  defaultThemeId = 7;

  getComponent() {
    const path = this.props.path || "404";
    const defaultThemeId = this.props.defaultThemeId || this.defaultThemeId;
    const themeId = this.props.themeId || defaultThemeId;

    import(`../${themeId}/${path}`)
      .then(module => {
        this.setState({ loaded: true, LazyComp: module.default, path });
      })
      .catch(reason => {
        import(`../${defaultThemeId}/${path}`)
          .then(module => {
            this.setState({ loaded: true, LazyComp: module.default, path });
          })
        });
      
      //     .catch(() => {
      //   import(`../Footers/${themeId}/Footer`)
      //     .then(module => {
      //       this.setState({ loaded: true, LazyComp: module.default });
      //     })
      //     .catch(()=> {
      //       import(`../Footers/1/Footer`)
      //       .then(module => {
      //         this.setState({ loaded: true, LazyComp: module.default });
      //       })
      //     });
      // })
  }
    
  componentDidUpdate(prevprops) {
    if (this.props.themeId != prevprops.themeId || this.props.path != prevprops.path) {
      this.getComponent();
    }
  }

  static getDerivedStateFromProps(props, state) {
      return props.path !== state.path
        ? { loaded: false, LazyComp: null, path: "" }
        : null;
  }

  constructor(props) {
    super(props);
    this.getComponent();
  }

  // componentWillMount() {
  //   const themeId = Number(this.props.themeId);
  //   const path = this.props.path || "Footer/Footer";
  //   this.getComponent(themeId, path);
  // }

  render() {
    const { loaded, LazyComp } = this.state;

    return loaded && <LazyComp {...this.props} />;
  }
}

export default LazyComponent;