import Users from "./Users";
import Data from "./Data";
import ProductUtils from "../utils/Product";
import { toast } from "react-toastify";

const origins = {
  BACKEND: "BACKEND",
  LOCAL: "LOCAL",
};

const types = {
  cart: {
    name: "cart",
    localStorageName: "carts",
    hasQuantity: true,
    addItemApi: Data.postCartItem,
    getListApi: Data.getCart,
    deleteItemApi: Data.deleteCartItem,
  },
  wishlist: {
    name: "wishlist",
    localStorageName: "wishlists",
    hasQuantity: false,
    addItemApi: Data.postWishlistItem,
    getListApi: Data.getWishlist,
    deleteItemApi: Data.deleteWishlistItem,
  },
};

export class ItemListHandler {
  constructor(typeName) {
    this.type = types[typeName] || types.cart;

    if (!this.type.hasQuantity) {
      delete this.modifyQuantityLocalList;
      delete this.modifyQuantity;
    }
  }

  getLocalLists = () => {
    return JSON.parse(localStorage.getItem(this.type.localStorageName)) || {};
  };

  getLocalList = (orgId) => {
    const { hasQuantity } = this.type;
    var list = this.getLocalLists()[orgId];
    if (!list || !list.items) {
      list = {
        items: [],
        ...(hasQuantity ? { subtotal: 0, discount: 0, total: 0 } : {}),
      };
    }
    return list;
  };

  mapBackendListToLocalList = (list) => {
    const { hasQuantity } = this.type;
    const localItems = list.items.map((item) => ({
      ...item,
      thumb: item.cover_img,
      ...(hasQuantity
        ? { total_price: (item.price - Number(item.discount)) * item.quantity }
        : {}),
    }));

    const localList = {
      ...list,
      origin: origins.BACKEND,
      items: localItems,
    };
    return localList;
  };

  getList = async (orgId) => {
    let list;
    list = this.getLocalList(orgId);
    list.origin = list.origin || origins.LOCAL;
    if (Users.getUser()) {
      try {
        const lists = await this.saveBackendList(orgId, list);
        list = lists[orgId];
        list.origin = origins.BACKEND;
      } catch (ex) {
        throw new Error("unexpected error");
      }
    }
    list.items.forEach((item) => {
      item.thumb = Data.getImageOrDefault(item.thumb);
    });
    return list;
  };

  saveLocalList = (orgId, list) => {
    const { hasQuantity, localStorageName } = this.type;
    var localLists = this.getLocalLists();
    if (hasQuantity && list.origin === origins.LOCAL) {
      list.total = list.subtotal = list.items
        .map((item) => item.total_price)
        .reduce((a, b) => {
          return a + b;
        }, 0);
      list.discount = 0;
    }
    localLists[orgId] = list;
    localStorage.setItem(localStorageName, JSON.stringify(localLists));
    return localLists;
  };

  saveBackendList = async (orgId, list) => {
    let lastList = list;
    let errors = [];
    if (list.origin !== origins.BACKEND && list?.items?.length) {
      for (let i = 0; i < list.items.length; i++) {
        const { stock_id, product_id, product_type, quantity, thumb } =
          list.items[i];
        try {
          lastList = this.mapBackendListToLocalList(
            await this.type.addItemApi({
              stock_id,
              product_id,
              product_type,
              quantity,
              cover_img: thumb,
            })
          );
        } catch (ex) {
          errors.push(ex);
          lastList = this.mapBackendListToLocalList(
            await this.type.getListApi()
          );
        }
      }
      if (errors.length)
        toast.warn(`some products coudn't be saved to your ${this.type.name}`);
    } else {
      try {
        lastList = this.mapBackendListToLocalList(await this.type.getListApi());
      } catch {
        errors.push(new Error(`couldn't get ${this.type.name} from server`));
      }
    }
    return this.saveLocalList(orgId, lastList);
  };

  mapCandidateToLocalItem = (itemCandidate) => {
    const { product, variant, quantity, item } = itemCandidate;
    if (item) return { ...item, quantity, total_price: item.price * quantity };
    const { images: variantImages } = variant;
    let { stock } = itemCandidate;
    if (!stock) {
      stock = ProductUtils.getStockWithLowerPrice(
        variant,
        this.type.hasQuantity ? quantity : 0
      );
    }
    if (this.type.hasQuantity && (!stock || stock.quantity < quantity))
      throw new Error("Requested quantity not available");

    const featuresArray = product?.variant_features?.map(({ label }) => label);
    const variant_features = featuresArray?.reduce((map, feature) => {
      map[feature] = variant[feature];
      return map;
    }, {});
    return {
      product_id: product.id,
      product_type: product.product_type,
      brand_id: product.brand_id,
      name: variant.name || product.name,
      p_name: product.p_name,
      variant_id: variant.id,
      variant_features,
      stock_id: stock.id,
      price: ProductUtils.getSalePrice(stock),
      ...(this.type.hasQuantity
        ? {
            quantity,
            total_price: ProductUtils.getSalePrice(stock) * quantity,
          }
        : {}),
      unit: "pcs",
      thumb:
        variantImages?.find(({ priority }) => priority === 0)?.url ||
        ProductUtils.getGallery(product, variant)[0],
    };
  };

  setBackendListItem = async (orgId, item) => {
    const { stock_id, product_id, product_type, quantity, thumb } = item;
    const newItem = {
      stock_id,
      product_id,
      product_type,
      cover_img: thumb,
    };

    if (this.type.hasQuantity) newItem.quantity = quantity;
    try {
      const list = this.mapBackendListToLocalList(
        await this.type.addItemApi(newItem)
      );
      return this.saveLocalList(orgId, list);
    } catch (ex) {
      throw new Error(ex?.response?.data?.message || "unexpected error");
    }
  };

  addToList = async (orgId, itemCandidate) => {
    let requestedItem = this.mapCandidateToLocalItem(itemCandidate);
    const list = await this.getList(orgId);
    const duplicateItem = list?.items?.find(
      (item) => requestedItem.stock_id === item.stock_id
    );
    let lists;

    if (this.type.hasQuantity) {
      const stocks =
        itemCandidate?.variant?.stocks?.reduce?.((map, stock) => {
          map[stock.id] = stock;
          return map;
        }, {}) || {};
      if (duplicateItem) {
        duplicateItem.total_price =
          duplicateItem.total_price + requestedItem.total_price;
        duplicateItem.quantity += requestedItem.quantity;
        duplicateItem.thumb = requestedItem.thumb || duplicateItem.thumb;
        requestedItem = duplicateItem;
      }
      if (
        !requestedItem.stock_id ||
        stocks[requestedItem.stock_id]?.quantity < requestedItem.quantity
      )
        throw new Error("Requested quantity not available");
    }

    if (Users.getUser()) {
      lists = this.setBackendListItem(orgId, requestedItem);
    } else {
      if (!duplicateItem) list.items.push(requestedItem);
      lists = this.saveLocalList(orgId, list);
    }
    return lists;
  };

  deleteFromLocalList = (orgId, list, itemIndex) => {
    list.origin = origins.LOCAL;
    list.items.splice(itemIndex, 1);
    return this.saveLocalList(orgId, list);
  };

  deleteFromBackendList = async (orgId, item) => {
    const { id } = item;
    let newList;
    try {
      newList = this.mapBackendListToLocalList(
        await this.type.deleteItemApi(id)
      );
    } catch (ex) {
      throw new Error(ex?.response?.data?.message || "unexpected error");
    }
    return this.saveLocalList(orgId, newList);
  };

  deleteFromList = async (orgId, stockId) => {
    let list = await this.getList(orgId);
    const itemIndex = list.items.findIndex((item) => item.stock_id === stockId);
    if (itemIndex < 0) {
      throw new Error("item not found");
    }
    if (Users.getUser())
      return this.deleteFromBackendList(orgId, list.items[itemIndex]);
    return this.deleteFromLocalList(orgId, list, itemIndex);
  };

  modifyQuantityLocalList = (orgId, list, item, quantity) => {
    item.total_price = (item.total_price / item.quantity) * quantity;
    item.quantity = quantity;
    return this.saveLocalList(orgId, list);
  };

  modifyQuantity = async (orgId, stockId, quantity) => {
    const list = await this.getList(orgId);
    const item = list.items.find((item) => item.stock_id === stockId);
    if (!item) {
      throw new Error("item not found");
    }
    if (Users.getUser())
      return this.setBackendListItem(orgId, { ...item, quantity });
    return this.modifyQuantityLocalList(orgId, list, item, quantity);
  };

  resetLocalList = async (orgId) => {
    const list = { items: [] };
    this.saveLocalList(orgId, list);
    await this.getList(orgId);
    return this.getLocalLists();
  };
}
