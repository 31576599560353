import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import "./Themes/Tseppas/i18n"; // Import i18n configuration
import { useTranslation } from "react-i18next";
import OldApp from "./OldApp";
import NotFound from "./Pages/404/NotFound";
import PasswordRecovery from "./Pages/PasswordRecovery/PasswordRecovery";
import PaymentPopupCallback from "./Pages/PaymentPopupCallback/PaymentPopupCallback";
import LocationModal from "./Themes/Tseppas/Components/LocationModal/LocationModal";
import Data from "./Services/Data";
import { isMultiLanguageEnabled } from "./Themes/Tseppas/Utils/themeHelpers";
import { OrganizationProvider } from "./Themes/Tseppas/Context/OrganizationContext";

export default function App() {
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [userLocation, setUserLocation] = useState(null);
    const [locationSettings, setLocationSettings] = useState(null);
    const [orgData, setOrgData] = useState(null);

    const getOrganizationData = async (orgName) => {
        try {
            let data;
            if (process.env.NODE_ENV === "development") {
                if (!orgName) {
                    throw new Error("No organization name provided");
                }
                Data.setHref(orgName);
                data = await Data.getOrgData(orgName);
            } else {
                data = await Data.getOrgDataByUrl(window.location);
                if (data.sub_dir) {
                    Data.setHref(orgName);
                } else {
                    Data.setHref("");
                }
            }

            setOrgData(data);

            // Check for location settings
            if (data?.settings?.location) {
                try {
                    const locationData = JSON.parse(data.settings.location);
                    setLocationSettings(locationData);

                    // Only show modal if no saved location exists
                    const savedLocation = localStorage.getItem("userLocation");
                    if (!savedLocation) {
                        setShowLocationModal(true);
                    }
                } catch (error) {
                    console.error("Error parsing location settings:", error);
                }
            }
        } catch (error) {
            console.error("Error fetching organization data:", error);
        }
    };

    useEffect(() => {
        const subDir = window.location.pathname.split("/")[1];
        getOrganizationData(subDir);
    }, []);

    const handleLocationSelect = (location) => {
        setUserLocation(location);
        localStorage.setItem("userLocation", JSON.stringify(location));
        setShowLocationModal(false);

        if (location.link) {
            window.location.href = location.link;
        }
    };
    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (!orgData) return;
        const isMultiLingual = isMultiLanguageEnabled(orgData);
        // Only apply RTL for Tseppas theme
        if (isMultiLingual) {
            document.documentElement.dir =
                i18n.language === "ar" ? "rtl" : "ltr";
        } else {
            document.documentElement.dir = "ltr";
        }
        document.documentElement.lang = i18n.language;
    }, [i18n.language, orgData]);

    return (
        <OrganizationProvider value={orgData}>
            <>
                {locationSettings && (
                    <LocationModal
                        isOpen={showLocationModal}
                        onClose={() => setShowLocationModal(false)}
                        onSelect={handleLocationSelect}
                        locations={locationSettings}
                    />
                )}
                <Switch>
                    <Route
                        path='/password_recovery'
                        component={PasswordRecovery}
                    />
                    <Route
                        path='/files'
                        render={() => (
                            <NotFound
                                errorMessage={"360 experince can't be found"}
                                link={false}
                                url={window.location}
                                urlCaption={"Reload"}
                            />
                        )}
                    />
                    <Route
                        path='/payment-complete'
                        component={PaymentPopupCallback}
                    />
                    <Route path='*' component={OldApp} />
                </Switch>
            </>
        </OrganizationProvider>
    );
}
