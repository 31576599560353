import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import "./notFound.scss";

export default function NotFound({
  errorCode,
  errorMessage,
  link,
  url,
  urlCaption,
}) {
  window.scrollTo(0, 0);

  return (
    <div id="notfound">
      <Helmet>
        <meta name="render:status_code" content="404" />
        <title>404 Not Found</title>
        <meta
          name="description"
          content={"Sorry, we couldn't find the requested page."}
        />
      </Helmet>

      <div className="container">
          <div className="contentWrapper">
            <figure className="serach-btn">
              <img src="/img/SearchPattern.png" className="search-pattern" alt="NasNav Search Pattern" />
            </figure>
            <div className="details">
              <h3>We lost this page</h3>
              <p>
                We searched high and low, but couldn&apos;t find what you&apos;re looking for.
                Let&apos;s find a better place for you to go.
              </p>
              <a href={url}>
                Go home
              </a>
            </div>
            <figure className="main-slide">
              <img src="/img/404Bg.png" alt="NasNav" />
            </figure>
          </div>
        </div>
    </div>
  );
};

NotFound.defaultProps = {
  errorCode: "404",
  errorMessage: "Page not found",
  link: true,
  url: "/",
  urlCaption: "Home page",
};

NotFound.propTypes = {
  errorCode: PropTypes.string,
  errorMessage: PropTypes.string,
  link: PropTypes.bool,
  url: PropTypes.string,
  urlCaption: PropTypes.string,
};
