
function* idGeneratorFunc() {
  let id = 1;
  while(true) {
    yield id++;
  }
}

const idGenerator = idGeneratorFunc();

export default {
  get nextId() {
    return idGenerator.next().value;
  }
}