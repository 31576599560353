/* eslint-disable no-extend-native */
import "react-app-polyfill/ie9";
import React from "react";
import { render } from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { toast } from "react-toastify";

import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "./Themes/Tseppas/i18n";
import App from "./App";

toast.configure({
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
});

function importBuildTarget() {
    if (process.env.REACT_APP_BUILD_YESHTERY === "true") {
        return import("./yeshtery/App");
    } else {
        return import("./App");
    }
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </BrowserRouter>
);

importBuildTarget()
    .then(({ default: App }) => {
        render(App);
    })
    .catch((e) => console.error("BUILD TARGET ERROR:", e));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
