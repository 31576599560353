/**
 * GoogleAnalytics class for tracking events using Google Analytics.
 * @class GoogleAnalytics
 * @property {string|null} siteId - Google Analytics Property ID.
 * @property {string|null} currency - The currency to use for tracking events.
 * @property {Object} customVariables - Custom variables for tracking events.
 * @returns {GoogleAnalytics} - GoogleAnalytics instance.
 */
export default class GoogleAnalytics {
    /**
     * Google Analytics Property ID.
     * @type {string|null}
     */
    siteId = null;

    /**
     * The currency to use for tracking events.
     * @type {string|null}
     */
    currency = null;

    /**
     * Constructor for the GoogleAnalytics class.
     *
     * @param {string|null} siteId - The Google Analytics Property ID.
     * @param {string|null} currency - The currency to use for tracking events.
     * @param {Object} customVariables - Custom variables for tracking events.
     */
    constructor(siteId, currency, customVariables = {}) {
        try {
            if (!siteId) return;

            this.siteId = siteId;
            this.currency = currency;

            // Append the Google Analytics script to the header
            this.appendGoogleAnalyticsScript();
        } catch (error) {
            console.log("#GoogleAnalytics - initialization error: ", error);
        }
    }

    /**
     * Function to append the Google Analytics script to the header.
     */
    appendGoogleAnalyticsScript = () => {
        if (typeof window.gtag === 'function') {
            // If gtag is already defined, don't load the script again.
            return;
        }

        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.siteId}`;
        document.head.appendChild(script);

        script.onload = () => {
            // Initialize the dataLayer (as per Google's standard)
            window.dataLayer = window.dataLayer || [];

            // Define the gtag function
            window.gtag = function gtag() {
                window.dataLayer.push(arguments);
            }

            window.gtag('js', new Date());
            window.gtag('config', this.siteId);
        }
    };

    /**
     * Function to track a new page.
     */
    trackNewLocation = () => {
        if (!window.gtag) return;

        window.gtag('event', 'page_view', {
            page_location: window.location.href,
            client_id: this.customVariables?.id || "",
        });
    };

    /**
     * Function to track a product view.
     *
     * @param {Object} product - Product information.
     */
    trackProductView = (product) => {
        try {
            if (!window.gtag) return;

            const { id, name, tags = [], price } = product;
            const [category] = tags;

            window.gtag('event', 'view_item', {
                items: [{
                    item_id: id,
                    item_name: name,
                    item_category: category?.name,
                    price: price,
                }],
                currency: this.currency,
            });
        } catch (error) {
            console.log("#GoogleAnalytics - track product view error: ", error);
        }
    };

    /**
     * Function to track a category view.
     *
     * @param {Object} category - Category information.
     */
    trackCategoryView = (category) => {
        try {
            if (!window.gtag) return;

            const { name, p_name } = category;
            window.gtag('event', 'view_item_list', {
                item_list_name: name,
                item_list_id: p_name
            });
        } catch (error) {
            console.log("#GoogleAnalytics - track category view error: ", error);
        }
    };

    /**
     * Function to track a cart.
     *
     * @param {Object} cart - Cart information.
     */
    trackCart = (cart) => {
        try {
            if (!window.gtag || !cart?.items) return;

            window.gtag('event', 'add_to_cart', {
                items: cart.items.map((item) => ({
                    items: [{
                        item_id: item.product_id,
                        item_name: item.name,
                        quantity: item.quantity,
                        price: item.price,
                    }],
                    currency: this.currency,
                })),
            });
        } catch (error) {
            console.log("#GoogleAnalytics - track cart error: ", error);
        }
    };

    /**
     * Function to track an order.
     *
     * @param {Object} order - Order information.
     */
    trackOrder = (order) => {
        try {
            if (!window.gtag || !order) return;

            const items = order.sub_orders
                .map(({ items }) => items)
                .reduce(
                    (allItems, subOrderItems) => [...allItems, ...subOrderItems],
                    []
                );

            window.gtag('event', 'purchase', {
                items: items.map((item) => ({
                    item_id: item.product_id,
                    item_name: item.name,
                    quantity: item.quantity,
                    price: item.price,
                    currency: this.currency,
                })),
                transaction_id: order.id,
                revenue: order.total,
            });
        } catch (error) {
            console.log("#GoogleAnalytics - track order error: ", error);
        }
    };
}
