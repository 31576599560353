import React from "react";
import "spinkit/spinkit.min.css";

const GridCube = ({ className, style }) => (
  <div className={["sk-grid", className].join("")} style={style}>
    <div className="sk-grid-cube" />
    <div className="sk-grid-cube" />
    <div className="sk-grid-cube" />
    <div className="sk-grid-cube" />
    <div className="sk-grid-cube" />
    <div className="sk-grid-cube" />
    <div className="sk-grid-cube" />
    <div className="sk-grid-cube" />
    <div className="sk-grid-cube" />
  </div>
);

export default GridCube;
