import React, { Suspense, Component } from "react";
import { Route, Switch } from "react-router-dom";
import { BounceLoader } from "../Themes/Shared/Components/Spinners";

import classes from "./MyAccount.module.scss";
import LazyPage from "../Pages/Multi/LazyPage";
import accountSections from "../utils/accountSections";

class MyAccount extends Component {

  updateActiveRoute = () => {
    const { activeRoute } = this.state;
    const { match: { params: { section: sectionName = "" } } } = this.props;
    const newActiveRoute = accountSections.find(section => section.path === sectionName) || accountSections.find(section => section.path === "");
    if (!activeRoute || activeRoute.path !== newActiveRoute.path) {
      this.setState({ activeRoute: newActiveRoute });
    }
  }

  fallback = () => {
    return (
      <div className={classes.spinnerContainer}>
        <BounceLoader topMsg="Please Wait" bottomMsg="Loading..." />
      </div>
    )
  }
  
  constructor(props) {
    super(props);

    this.state = {
      activeRoute: null
    }

  }

  componentDidMount() {
    this.updateActiveRoute();
  }

  componentDidUpdate() {
    this.updateActiveRoute();
  }

  render() {
    const { organizationName, orgId, prefix, themeId } = this.props;
    const { activeRoute } = this.state;

    const sortedSections = accountSections.sort((a, b) => b.order - a.order);

    return (
      <Suspense fallback={this.fallback}>
        <Switch>
          {sortedSections.map(({ path, pagePath }) => (
            <Route
              exact={path !== ""}
              path={path !== "" ? `${prefix}/${path}` : `/`}
              render={(props) => (
                <LazyPage
                  path={pagePath}
                  themeId={themeId}
                  organizationName={organizationName}
                  orgId={orgId}
                  activeRoute={activeRoute}
                  {...props}
                />
              )}
            />
          ))}
        </Switch>
      </Suspense>
    );
  }
}

export default MyAccount;
