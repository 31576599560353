import React from "react";

export const OrganizationContext = React.createContext(null);

export const OrganizationProvider = ({ children, value }) => {
    return (
        <OrganizationContext.Provider value={value}>
            {children}
        </OrganizationContext.Provider>
    );
};
