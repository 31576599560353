import React from "react";
import { Helmet } from "react-helmet";

const PaymentPopupCallback = () => {
  window.opener.dataComplete(new URLSearchParams(window.location.search));
  window.close();
  return (
    <Helmet>
      <title>loading</title>
    </Helmet>
  );
};

export default PaymentPopupCallback;
