import Http from "./Http";

let orgId;

const getErrorMessage = (flag, fallbackMessage = "Unknown error") => {
  switch (flag) {
    case "U$LOG$0007":
      return "Email is already registered";
    case "U$EMP$0004":
      return "Email is invalid";
    case "U$EMP$0003":
      return "Name is invalid";
    case "U$LOG$0005":
      return "Password is not in the correct format";
    case "UXACTVX0001":
      return "This email is not registered";
    case "U$LOG$0003":
      return "Account needs activation, please check your email";
    case "U$LOG$0002":
      return "Email or password is incorrect";
    case "U$LOG$0004":
      return "This account is locked down";
    case "U$LOG$0006":
      return "Invalid token";
    case "UXACTVX0002":
      return "Email already confirmed";
    case "SYS_ERROR":
      return "System error";
    case "U$LOG$0001":
      return "Invalid recovery token";
    case "UXACTVX0005":
      return "Recovery token not provided";
    case "U$LOG$0009":
      return "This email is already subscribed";
    default:
      return fallbackMessage;
  }
};

const init = (id, unauthorizedCallback) => {
  orgId = id;
  const user = getUser();
  if (user) {
    const token = user.token;
    const id = user.id;
    Http.startUserSession(id, token, unauthorizedCallback);
  }
};

const getSavedEmails = () => {
  return JSON.parse(localStorage.getItem("remember")) || {};
};

const getSavedEmail = () => {
  return getSavedEmails()[orgId] || "";
};

const saveEmail = (email) => {
  const savedEmails = getSavedEmails();
  savedEmails[orgId] = email;
  localStorage.setItem("remember", JSON.stringify(savedEmails));
};

const getUsers = () => {
  return JSON.parse(localStorage.getItem("users")) || {};
};

const getUser = () => {
  const users = getUsers();
  return users ? users[orgId] : null;
};

const saveUser = (user) => {
  const users = getUsers();
  users[orgId] = user;
  localStorage.setItem("users", JSON.stringify(users));
};

const logout = async (localOnly) => {
  if (!localOnly) {
    try {
      await Http.post(Http.backendUrl + "/user/logout");
    } catch (ex) {
      if (
        ex.response?.status !== 401 &&
        !window.confirm("an error occured!\nwould you like to force logout?")
      )
        throw new Error("an error occured!\ncouldn't log out");
    }
  }
  saveUser(undefined);
  Http.endUserSession();
};

const recover = async (password, token) => {
  const { data } = await Http.post(Http.backendUrl + "/user/recover", {
    password,
    token,
  });
  return data;
};

const forgot = async (email, orgId) => {
  const { data } = await Http.get(Http.backendUrl + "/user/recover", {
    params: {
      employee: false,
      email,
      org_id: orgId,
    },
  });
  return data;
};

const sendActivation = async (email, orgId, redirectUrl) => {
  const { data } = await Http.post(
    Http.backendUrl + "/user/v2/register/activate/resend",
    {
      email,
      org_id: orgId,
      redirect_url: redirectUrl,
    }
  );
  return data;
};

const activate = (activationToken) =>
  Http.post(
    Http.backendUrl + `/user/v2/register/activate?token=${activationToken}`
  );

const login = async (credentials, unauthorizedCallback, orgId, remember) => {
  const { email, password, oauthToken, activationToken } = credentials;
  if (
    Boolean(oauthToken) +
      Boolean(email || password) +
      Boolean(activationToken) >
    1
  )
    throw new Error("unspecified login method");
  const emailLoginRequest = () =>
    Http.post(Http.backendUrl + "/user/login", {
      email,
      org_id: orgId,
      password,
    });
  const socialLoginRequest = () =>
    Http.post(Http.backendUrl + `/user/login/oauth2?token=${oauthToken}`);
  const confirmation = () => activate(activationToken);
  const loginRequest = oauthToken
    ? socialLoginRequest
    : activationToken
    ? confirmation
    : emailLoginRequest;

  const { data } = await loginRequest();
  Http.startUserSession(data.id, data.token, unauthorizedCallback);
  saveUser(data);
  saveEmail(remember ? credentials.email : "");
  return data;
};

const getProfile = async () => {
  const { data } = await Http.get(`${Http.backendUrl}/user/info`);
  return data;
};

const updateProfile = async (newProfile) => {
  const { data } = await Http.post(
    `${Http.backendUrl}/user/update`,
    newProfile
  );
  return data;
};

const updateAddress = async (newAddress) => {
  const { data } = await Http.put(
    `${Http.backendUrl}/user/address`,
    newAddress
  );
  return data;
};

const deleteAddress = async (addressId) => {
  const { data } = await Http.delete(`${Http.backendUrl}/user/address`, {
    params: { id: addressId },
  });
  return data;
};

const register = async (
  email,
  name,
  orgId,
  password,
  confirmationFlag,
  redirectUrl
) => {
  if (!confirmationFlag) throw new Error("Passwords must match");
  const { data } = await Http.post(Http.backendUrl + "/user/v2/register", {
    confirmation_flag: confirmationFlag,
    email,
    name,
    org_id: orgId,
    password,
    redirect_url: redirectUrl,
  });
  return data;
};

const subscribe = async (email, orgId) => {
  const { data } = await Http.post(Http.backendUrl + "/user/subscribe", null, {
    params: {
      email,
      org_id: orgId,
    },
  });
  return data;
};

export default {
  init,
  useNewApi: Http.useNewApi,
  login,
  register,
  recover,
  forgot,
  logout,
  getErrorMessage,
  getUser,
  getProfile,
  getSavedEmail,
  updateProfile,
  updateAddress,
  deleteAddress,
  sendActivation,
  subscribe,
};
