import Users from "./Users";
import Data from "./Data";
import ProductUtils from "../utils/Product";
import Wishlists from "./Wishlists";
import { toast } from "react-toastify";

const types = {
  BACKEND: "BACKEND",
  LOCAL: "LOCAL",
};

const getLocalCarts = () => {
  return JSON.parse(localStorage.getItem("carts")) || {};
};

const getLocalCart = (orgId) => {
  var cart = getLocalCarts()[orgId];
  if (!cart || !cart.items) {
    cart = { items: [], subtotal: 0, discount: 0, total: 0 };
  }
  return cart;
};

const mapBackendCartToLocalCart = (cart) => {
  const localItems = cart.items.map((item) => ({
    ...item,
    thumb: item.cover_img,
    total_price: (item.price - Number(item.discount)) * item.quantity,
  }));
  return {
    ...cart,
    type: types.BACKEND,
    items: localItems,
  };
};

const getCart = async (orgId, promocode) => {
  let cart;
  cart = getLocalCart(orgId);
  cart.type = cart.type || types.LOCAL;
  if (Users.getUser()) {
    try {
      const carts = await saveBackendCart(orgId, cart, promocode);
      
      cart = carts[orgId];
      cart.type = types.BACKEND;
    } catch (ex) {
      throw new Error("unexpected error");
    }
  }
  cart.items.forEach((item) => {
    item.thumb = Data.getImageOrDefault(item.thumb);
  });
  return cart;
};

const saveLocalCart = (orgId, cart) => {
  var localCarts = getLocalCarts();
  if (cart.type === types.LOCAL) {
    cart.total = cart.subtotal = cart.items
    .map(item => item.total_price)
    .reduce((a, b) => {
      return a + b;
    }, 0);
    cart.discount = 0;
  }
  localCarts[orgId] = cart;
  localStorage.setItem("carts", JSON.stringify(localCarts));
  return localCarts;
};

const markItemAsOptimized = (item) => {
  if (item) item.optimized = true;
};

const copyOptimizedFlag = (oldCart, newCart) => {
  const optimizedStockIds = new Set(
    oldCart.items
      .filter((item) => item.optimized)
      .map((item) => item.stock_id)
  );
  newCart.items.forEach(item => {
    if (optimizedStockIds.has(item.stock_id)) {
      markItemAsOptimized(item);
    }
  })
}

const saveBackendCart = async (orgId, cart, promocode) => {
  let lastCart = cart;
  let errors = [];
  if (cart.type !== types.BACKEND && cart?.items?.length) {
    for (let i = 0; i < cart.items.length; i++) {
      const {
        stock_id,
        product_id,
        product_type,
        quantity,
        thumb,
      } = cart.items[i];
      try {
        lastCart = mapBackendCartToLocalCart(
          await Data.postCartItem({
            stock_id,
            product_id,
            product_type,
            quantity,
            cover_img: thumb,
          })
        );
      } catch (ex) {
        errors.push(ex);
        lastCart = mapBackendCartToLocalCart(await Data.getCart(promocode));
        
      }
    }
    if (errors.length) toast.warn("some products coudn't be saved to your cart");
  } else {
    try {
      lastCart = mapBackendCartToLocalCart(await Data.getCart(promocode));
    } catch {
      errors.push(new Error("couldn't get cart from server"));
    }
  }
  // to forget optimization state after session change move next line to get cart after saveBackendCart call
  copyOptimizedFlag(cart, lastCart);
  return saveLocalCart(orgId, lastCart);
};

const mapCandidateToLocalItem = (itemCandidate) => {
  const { product, variant, quantity, item, addon_list, special_order } = itemCandidate;
  if (item) return { ...item, quantity , total_price: item.price * quantity, addon_list, special_order };
  const { images: variantImages } = variant;
  let { stock } = itemCandidate;
  if (!stock) {
    stock = ProductUtils.getStockWithLowerPrice(variant, quantity);
  }
  if (!stock || stock.quantity < quantity)
    throw new Error("Requested quantity not available");

  const featuresArray = product.variant_features.map(({ label }) => label);
  const variant_features = featuresArray.reduce((map, feature) => {
    map[feature] = variant[feature];
    return map;
  }, {});
  return {
    product_id: product.id,
    product_type: product.product_type,
    brand_id: product.brand_id,
    name: variant.name || product.name,
    p_name: product.p_name,
    variant_id: variant.id,
    variant_features,
    stock_id: stock.id,
    quantity,
    total_price: ProductUtils.getSalePrice(stock) * quantity,
    thumb:
      variantImages.find(({ priority }) => priority === 0)?.url ||
      ProductUtils.getGallery(product, variant)[0],
    addon_list,
    special_order
  };
};

const setBackendCartItem = async (orgId, item, oldCart) => {
  const {
    stock_id,
    product_id,
    product_type,
    quantity,
    thumb,
    optimized,
    addon_list,
    special_order
  } = item;
  try {
    const backendCart = await Data.postCartItem({
      stock_id,
      product_id,
      product_type,
      quantity,
      cover_img: thumb,
      addon_list,
      special_order
    });
    if (optimized) {
      markItemAsOptimized(
        backendCart.items.find(
          (backendItem) => backendItem.stock_id === item.stock_id
        )
      );
    }
    copyOptimizedFlag(oldCart, backendCart);
    const cart = mapBackendCartToLocalCart(backendCart);
    return saveLocalCart(orgId, cart);
  } catch (ex) {
    throw new Error(ex?.response?.data?.message || "unexpected error");
  }
}

const addToCart = async (orgId, itemCandidate) => {
  const stocks = itemCandidate?.variant?.stocks?.reduce?.((map, stock) => {
    map[stock.id] = stock;
    return map;
  }, {}) || {};
  let requestedItem = mapCandidateToLocalItem(itemCandidate);
  const cart = await getCart(orgId);
  let carts;
  const duplicateItem = cart.items.find(
    (item) =>
      requestedItem.stock_id === item.stock_id ||
      (item.optimized &&
        requestedItem.variant_id === item.variant_id)
  );
  
  if (duplicateItem) {
    duplicateItem.total_price += requestedItem.total_price;
    duplicateItem.quantity += requestedItem.quantity;
    duplicateItem.thumb = requestedItem.thumb || duplicateItem.thumb;
    duplicateItem.addon_list = itemCandidate?.addon_list ? itemCandidate?.addon_list : requestedItem.addon_list;
    duplicateItem.special_order = itemCandidate?.special_order ? itemCandidate?.special_order : requestedItem.special_order;
    requestedItem = duplicateItem;
  }

  if (
    !requestedItem.stock_id ||
    stocks[requestedItem.stock_id]?.quantity < requestedItem.quantity
  )
    throw new Error("Requested quantity not available");

  if (Users.getUser()) {
    carts = setBackendCartItem(orgId, requestedItem, cart);
  } else {
    if (!duplicateItem) cart.items.push(requestedItem);
    carts = saveLocalCart(orgId, cart);
  }
  return carts;
};

const deleteFromLocalCart = (orgId, cart, itemIndex) => {
  cart.type = types.LOCAL;
  cart.items.splice(itemIndex, 1);
  return saveLocalCart(orgId, cart);
};

const deleteFromBackendCart = async (orgId, item, oldCart) => {
  const { id } = item;
  let newCart;
  try {
    const backendCart = await Data.deleteCartItem(id);
    copyOptimizedFlag(oldCart, backendCart);
    newCart = mapBackendCartToLocalCart(backendCart);
  } catch (ex) {
    throw new Error(ex?.response?.data?.message || "unexpected error");
  }
  return saveLocalCart(orgId, newCart);
};

const deleteFromCart = async (orgId, stockId) => {
  let cart = await getCart(orgId);
  const itemIndex = cart.items.findIndex((item) => item.stock_id === stockId);
  if (itemIndex < 0) {
    throw new Error("item not found");
  }
  if (Users.getUser()) return deleteFromBackendCart(orgId, cart.items[itemIndex], cart);
  return deleteFromLocalCart(orgId, cart, itemIndex)
};

const modifyQuantityLocalCart = (orgId, cart, item, quantity) => {
  item.total_price = (item.total_price / item.quantity) * quantity;
  item.quantity = quantity;
  return saveLocalCart(orgId, cart);
};

const modifyQuantity = async (orgId, stockId, quantity) => {
  const cart = await getCart(orgId);
  const item = cart.items.find((item) => item.stock_id === stockId);
  if (!item) {
    throw new Error("item not found");
  }
  if (Users.getUser()) return setBackendCartItem(orgId, { ...item, quantity }, cart);
  return modifyQuantityLocalCart(orgId, cart, item, quantity);
};

const cartOptimizer = async function* (orgId, addressId, shippingServiceId, promocode, additionalData, payFromReferralBalance, referralCode) {
  const cartPromise = getCart(orgId, promocode);
  const OptimizePromise = Data.postCartOptimize(
    addressId,
    shippingServiceId,
    promocode,
    additionalData,
    payFromReferralBalance,
    referralCode,
  );
  const [oldCart, optimizationResult] = await Promise.all([cartPromise, OptimizePromise])
  const {
    total_changed: totalChanged,
    items_changed: itemsChanged,
    cart: optimizedCart,
  } = await optimizationResult;

  const newItemsById = {};

  optimizedCart.items.forEach(item => {
    if (item.quantity) {
      newItemsById[item.id] = item;
    }
  });

  const changedItems = oldCart.items
    .map((item) => ({
      ...item,
      ...(newItemsById[item.id] || { removed: true }),
      oldPrice: item.price,
      oldDiscount: item.discount,
      oldQuantity: item.quantity,
    }))
    .filter(
      (item) =>
        item.removed ||
        item.price !== item.oldPrice ||
        item.quantity !== item.oldQuantity
    );

  const confirmationStatus = yield { totalChanged, itemsChanged, changedItems };
  if (confirmationStatus) {
    const items = optimizedCart.items.map((item) => ({
      stock_id: item.stock_id,
      product_id: item.product_id,
      product_type: item.product_type,
      quantity: item.quantity,
      cover_img: item.cover_img,
    }));
    const newCart = await Data.postCartItems(items, promocode);
    newCart.items.forEach(markItemAsOptimized);
    return saveLocalCart(orgId, mapBackendCartToLocalCart(newCart));
  } else {
    return getLocalCarts();
  }
}

const moveWishlistItemToCart = async (orgId, item, quantity) => {
  if (item.id && Users.getUser()) {
    const apiCart = await Data.moveWishlistITemToCart(item.id, quantity);
    const wishlist = await Wishlists.getWishlist(orgId);
    const { [orgId]: cart } = saveLocalCart(
      orgId,
      mapBackendCartToLocalCart(apiCart)
    );
    return { cart, wishlist };
  } else {
    throw new Error("you need to log in first");
    // const { [orgId]: cart } = await addToCart(orgId, { item, quantity });
    // const { [orgId]: wishlist } = await Wishlists.deleteFromWishlist(orgId, item.stock_id);
    // return { cart, wishlist };
  }
}

const resetLocalCart = async (orgId) => {
  const cart = { items: [] };
  saveLocalCart(orgId, cart);
  await getCart(orgId);
  return getLocalCarts();
};

const checkout = async (
  orgId,
  customerAddressId,
  shippingServiceId,
  additionalData,
  promoCode,
  notes,
  payFromReferralBalance,
  referralCode
) => {
  return Data.postCartCheckout(
    customerAddressId,
    shippingServiceId,
    additionalData,
    promoCode,
    notes,
    payFromReferralBalance,
    referralCode
  );
};

const confirmOrder = async (orgId, order_id) => {
  // console.log("@payment confirm order_id", order_id);
  // const cart = await getCart(orgId);

  if (!order_id) throw new Error("No orderId");
  // var order = await Data.getOrderInfo(order_id);
  // // console.log("@payment confirm order",order);

  // if (order.status !== "CLIENT_CONFIRMED") {
  //   order.status = "CLIENT_CONFIRMED";
  //   await Data.postOrderUpdate(order);
  // }
  return resetLocalCart(orgId);
};

const updateLocalCart = async ({ orgId, newData }) => {
  const carts = JSON.parse(localStorage.getItem("carts"));
  const newCarts = { ...carts, [orgId]: { ...carts?.[orgId], ...newData } };
  localStorage.setItem("carts", JSON.stringify(newCarts));
}

export default {
  getLocalCarts,
  getCart,
  saveCart: saveBackendCart,
  addToCart,
  deleteFromCart,
  modifyQuantity,
  moveWishlistItemToCart,
  resetLocalCart,
  checkout,
  confirmOrder,
  cartOptimizer,
  updateLocalCart
};
